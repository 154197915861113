import React, { Component, Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import AdjustScoreInfoModal from "../Shared/Modals/AdjustScoreInfoModal";
import PropTypes from "prop-types";
import {
  confirmPostScore,
  confirmPostScoreReset,
  fetchCourseDetails,
  fetchCourseDetailsReset,
  postTotalScore,
  postTotalScoreReset,
} from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PostInvalidScoreModal from "../../components/Shared/Modals/PostInvalidScoreModal";
import NoTeesModal from "../Shared/Modals/NoTeesModal";
import NoActiveTeesModal from "../Shared/Modals/NoActiveTeesModal";
import NoSlopeRatingModal from "../../../../shared/components/modals/NoSlopeRatingModal";
import RoundSetupForm from "./RoundSetupForm";
import PostScoreConfirmationModal from "../Shared/Modals/PostScoreConfirmationModal";
import jobStatus from "../../../../shared/variables/job-status";
import scoreValidity from "../../../../shared/variables/score-validity";
import { golferId } from "../../../../shared/helpers/api-helper";
import { getParamFromUrl } from "../../../../shared/helpers/querystring-helper";
import { routesContext } from "../../../../shared/routes/routes-context";
import { manualCourse } from "../../variables/post-score-variables";
import PostScoreApiErrorsModal from "../Shared/Modals/PostScoreApiErrorsModal";
import {
  getPostScoreApiErrors,
  hasGenderTeeSets,
  hasGenderSlopeRating,
} from "../../helpers/round-setup-helper";
import { getUserGender } from "../../../../shared/helpers/user-helper";
import jobStats from "../../../../shared/variables/job-status";
import Loader from "../../../../shared/components/loaders/Loader";
import { Helmet } from "react-helmet";
import {
  MANUALLY_POST_SCORE_TITLE,
  MANUALLY_POST_SCORE_DESC,
  POST_TOTAL_SCORE_TITLE,
  POST_TOTAL_SCORE_DESC,
} from "../../../../shared/variables/meta-seo";
import HbhNotSameDayModal from "../Shared/Modals/HbhNotSameDayModal";
class PostTotalScore extends Component {
  static contextType = routesContext;
  constructor(props) {
    super(props);
    this.state = {
      isManualCourse: true,
      infoModalOpen: false,
      noTeesModalOpen: false,
      noActiveTeesModalOpen: false,
      noSlopeRatingModalOpen: false,
      postConfirmationModalOpen: false,
      postScoreApiErrorsModalOpen: false,
      invalidScoreModalOpen: false,
      invalidScoreModalMessage: "",
      hbhNotSameDayModalOpen: false,
    };
  }

  componentDidMount = () => {
    const { fetchCourseDetails, match } = this.props;

    if (match.params.courseId === manualCourse) {
      this.setState({ isManualCourse: true });
    } else {
      this.setState({ isManualCourse: false });
      fetchCourseDetails(match.params.courseId);
    }

    window.scrollTo(0, 0);
  };

  componentDidUpdate(prevProps) {
    const {
      course,
      postTotalScoreStatus,
      postedScore,
      confirmedScore,
      confirmPostScoreStatus,
    } = this.props;

    if (this.isPostTotalScoreSuccess(postTotalScoreStatus, prevProps)) {
      postedScore.status === scoreValidity.UNDER_REVIEW
        ? this.setState({ postConfirmationModalOpen: true })
        : this.context.postScoreTotalSummary.navigate(postedScore, course);
    } else if (this.isPostTotalScoreFailed(prevProps)) {
      this.setState({ postScoreApiErrorsModalOpen: true });
    }

    if (this.isConfirmPostSuccess(confirmPostScoreStatus, prevProps)) {
      this.context.postScoreTotalSummary.navigate(confirmedScore, course);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { course } = nextProps;
    if (course && course !== this.props.course) {
      const gender = getUserGender();
      const teeSets = hasGenderTeeSets(course, gender);
      if (!teeSets) {
        this.setState({ noActiveTeesModalOpen: true });
      }

      const slopeRating = hasGenderSlopeRating(course, gender);
      if (teeSets && !slopeRating) {
        this.setState({ noSlopeRatingModalOpen: true });
      }
    }
  }

  isPostTotalScoreSuccess = (status, prevProps) =>
    status !== prevProps.postTotalScoreStatus && status === jobStatus.SUCCESS;

  isPostTotalScoreFailed = (prevProps) =>
    this.props.postTotalScoreStatus === jobStatus.FAILED &&
    this.props.postTotalScoreErrors &&
    this.props.postTotalScoreErrors !== prevProps.postTotalScoreErrors;

  isConfirmPostSuccess = (status, prevProps) =>
    status !== prevProps.confirmPostTotalScoreStatus &&
    status === jobStatus.SUCCESS;

  alertInvalidScore = (message) => {
    this.setState({
      invalidScoreModalOpen: true,
      invalidScoreModalMessage: message,
    });
  };

  alertNoTees = () => {
    this.setState({ noTeesModalOpen: true });
  };

  openInfoModal = () => {
    this.setState({ infoModalOpen: true });
  };

  getManuallyMetaTags = () => {
    return (
      <Helmet>
        <title>{MANUALLY_POST_SCORE_TITLE}</title>
        <meta name="description" content={MANUALLY_POST_SCORE_DESC} />
      </Helmet>
    );
  };
  getMetaTags = () => {
    return (
      <Helmet>
        <title>{POST_TOTAL_SCORE_TITLE}</title>
        <meta name="description" content={POST_TOTAL_SCORE_DESC} />
      </Helmet>
    );
  };

  render() {
    const {
      infoModalOpen,
      noTeesModalOpen,
      noActiveTeesModalOpen,
      noSlopeRatingModalOpen,
      isManualCourse,
      invalidScoreModalOpen,
      invalidScoreModalMessage,
      postConfirmationModalOpen,
      postScoreApiErrorsModalOpen,
      hbhNotSameDayModalOpen,
    } = this.state;

    const {
      match,
      course,
      postTotalScore,
      confirmPostScore,
      postedScore,
      postTotalScoreErrors,
      fetchCourseDetailsStatus,
      postTotalScoreStatus,
    } = this.props;
    const showLoading =
      match.params.courseId !== manualCourse &&
      fetchCourseDetailsStatus !== jobStats.SUCCESS;
    return (
      <Fragment>
        {this.state.isManualCourse
          ? this.getManuallyMetaTags()
          : this.getMetaTags()}
        <div className="box">
          <div className="box-head">
            <h3 className="box-title">Post Total Score</h3>
          </div>

          {showLoading ? (
            <Loader />
          ) : (
            <div className="box-body">
              <RoundSetupForm
                course={course}
                trialGolfer={this.props.trialGolfer}
                isManualCourse={isManualCourse}
                manualCourseName={getParamFromUrl("courseName")}
                alertNoTees={this.alertNoTees}
                alertInvalidScore={this.alertInvalidScore}
                openInfoModal={this.openInfoModal}
                onSubmit={postTotalScore}
                postTotalScoreStatus={postTotalScoreStatus}
                openHbhNotSameDayModal={() =>
                  this.setState({ hbhNotSameDayModalOpen: true })
                }
              />
            </div>
          )}
        </div>
        <AdjustScoreInfoModal
          isOpen={infoModalOpen}
          onAbort={() => this.setState({ infoModalOpen: false })}
        />
        <NoTeesModal
          isOpen={noTeesModalOpen}
          onClose={() => this.setState({ noTeesModalOpen: false })}
        />
        <NoActiveTeesModal
          course={course}
          isOpen={noActiveTeesModalOpen}
          onClose={() => this.setState({ noActiveTeesModalOpen: false })}
        />
        <NoSlopeRatingModal
          isOpen={noSlopeRatingModalOpen}
          onClose={() => this.setState({ noSlopeRatingModalOpen: false })}
        />
        <PostInvalidScoreModal
          message={invalidScoreModalMessage}
          isOpen={invalidScoreModalOpen}
          onClose={() => this.setState({ invalidScoreModalOpen: false })}
        />
        <PostScoreConfirmationModal
          isOpen={postConfirmationModalOpen}
          validationMessage={postedScore?.validation_message_display}
          onAbort={() => this.setState({ postConfirmationModalOpen: false })}
          onConfirm={() => {
            this.setState({ postConfirmationModalOpen: false });
            confirmPostScore(postedScore.id, golferId());
          }}
        />
        <PostScoreApiErrorsModal
          errors={getPostScoreApiErrors(postTotalScoreErrors)}
          isOpen={postScoreApiErrorsModalOpen}
          onClose={() => this.setState({ postScoreApiErrorsModalOpen: false })}
        />
        <HbhNotSameDayModal
          trialGolfer={this.props.trialGolfer}
          isOpen={hbhNotSameDayModalOpen}
          onClose={() => this.setState({ hbhNotSameDayModalOpen: false })}
        />
      </Fragment>
    );
  }

  componentWillUnmount() {
    const {
      postTotalScoreReset,
      confirmPostScoreReset,
      fetchCourseDetailsReset,
    } = this.props;
    postTotalScoreReset();
    confirmPostScoreReset();
    fetchCourseDetailsReset();
  }
}

PostTotalScore.propTypes = {
  course: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  fetchCourseDetails: PropTypes.func.isRequired,
  fetchCourseDetailsReset: PropTypes.func.isRequired,
  postTotalScore: PropTypes.func.isRequired,
  postedScore: PropTypes.object.isRequired,
  postTotalScoreStatus: PropTypes.string.isRequired,
  confirmedScore: PropTypes.object.isRequired,
  confirmPostScoreStatus: PropTypes.string.isRequired,
  confirmPostScore: PropTypes.func.isRequired,
  postTotalScoreReset: PropTypes.func.isRequired,
  confirmPostScoreReset: PropTypes.func.isRequired,
  postTotalScoreErrors: PropTypes.object,
  fetchCourseDetailsStatus: PropTypes.string.isRequired,
};

const mapStateToProps = ({ postScoreReducer, profileReducer }) => ({
  course: postScoreReducer.courseDetailsFetchReducer.course,
  fetchCourseDetailsStatus:
    postScoreReducer.courseDetailsFetchReducer.fetchCourseDetailsStatus,
  trialGolfer: profileReducer.golferProfileFetchReducer.trialGolfer,
  postedScore: postScoreReducer.postTotalScoreReducer.postedScore,
  postTotalScoreStatus:
    postScoreReducer.postTotalScoreReducer.postTotalScoreStatus,
  postTotalScoreErrors:
    postScoreReducer.postTotalScoreReducer.postTotalScoreErrors,
  confirmedScore: postScoreReducer.postScoreConfirmationReducer.confirmedScore,
  confirmPostScoreStatus:
    postScoreReducer.postScoreConfirmationReducer.confirmPostScoreStatus,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCourseDetails,
      fetchCourseDetailsReset,
      postTotalScore,
      postTotalScoreReset,
      confirmPostScore,
      confirmPostScoreReset,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PostTotalScore);
