import { parseAuthError } from "../../../shared/helpers/api-helper-b";
import jobStatus from "../../../shared/variables/job-status";

import {
  RESEND_VERIFICATION,
  RESEND_VERIFICATION_SUCCESS,
  RESEND_VERIFICATION_FAILED,
} from "../actions";

const initialState = {
  status: jobStatus.NOT_STARTED,
  successMessage: undefined,
  error: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case RESEND_VERIFICATION:
      return {
        ...state,
        status: jobStatus.PROCESSING,
      };
    case RESEND_VERIFICATION_SUCCESS: {
      return {
        ...state,
        status: jobStatus.SUCCESS,
        successMessage: payload.success,
      };
    }
    case RESEND_VERIFICATION_FAILED: {
      return {
        ...state,
        status: jobStatus.FAILED,
        error: parseAuthError(payload?.errors),
      };
    }
    default:
      return state;
  }
};
