import React, { Component, Fragment } from "react";
import PromoBanner from "../../../shared/components/banners/PromoBanner";
import { Redirect, Route, Switch } from "react-router-dom";
import { routesContext } from "../../../shared/routes/routes-context";
import PropTypes from "prop-types";

class MyCourses extends Component {
  static contextType = routesContext;
  render = () => (
    <Fragment>
      <PromoBanner />
      <section className="post-score">
        <Switch>
          <Redirect
            exact
            from={this.context.myCourses.path}
            to={this.context.myCoursesSelectCourse.path}
          />
          <Route {...this.context.myCoursesSelectCourse} />
          <Route {...this.context.selectTee} />
          <Route {...this.context.addCourses} />
        </Switch>
      </section>
    </Fragment>
  );
}

MyCourses.propTypes = {
  location: PropTypes.object.isRequired,
};

export default MyCourses;
