import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ScoreType from "../../../../shared/variables/stats-score-type";
import jobStatus from "../../../../shared/variables/job-status";
import Loader from "../../../../shared/components/loaders/Loader";
import LoadMore from "../../../../shared/components/loaders/LoadMore";
import { getStatsScores, fetchStatsScoresNext } from "../../actions";
import HeaderFilterView from "../shared/HeaderFilterView";
import ContentList from "../shared/ContentList";
import { getUserGhinNo } from "../../../../shared/helpers/user-helper";
import { routesContext } from "../../../../shared/routes/routes-context";
import { Helmet } from "react-helmet";
import {
  STATS_SC_HISTORY_TITLE,
  STATS_SC_HISTORY_DESC,
} from "../../../../shared/variables/meta-seo";

class ScoreHistory extends Component {
  static contextType = routesContext;
  state = {
    filter: this.props.golferActive
      ? ScoreType.RECENT_AND_REVISION_SCORES
      : ScoreType.YEAR,
    selectedClub: undefined,
    extraParams: undefined,
  };

  componentDidMount() {
    this.props.getStatsScores(getUserGhinNo(), this.state.filter);
  }

  fetchMoreScores = () => {
    const { filter, extraParams } = this.state;
    const { offset } = this.props;
    this.props.fetchStatsScoresNext(filter, offset, false, extraParams);
  };

  handleChangeFilter = (filter, extraParams = undefined) => {
    this.setState({
      filter: filter,
      selectedClub:
        filter !== ScoreType.BY_COURSE ? undefined : this.state.selectedClub,
      extraParams:
        filter !== ScoreType.BY_COURSE_ID_AND_TEE_SET_ID
          ? undefined
          : extraParams,
    });
    this.props.getStatsScores(getUserGhinNo(), filter, false, extraParams);
  };

  handleChangeClub = (selectedClub) => {
    this.setState({ selectedClub });
  };

  viewAvgScorePerHole = (selectedClub) => {
    this.context.viewAvgScorePerHole.navigate(getUserGhinNo(), selectedClub);
  };

  onEditOrViewRoundStats = (score, flag) => {
    if (score.course_id) {
      this.context.statsCharts.navigate(score, flag);
    } else {
      this.context.statsEditCombinedScore.navigate(score, flag);
    }
  };

  render() {
    const {
      offset,
      scores,
      fetchStatus,
      fetchNextStatus,
      scores9Holes,
      additionalData,
      recentScores,
      deletedScores,
      revisionScores,
    } = this.props;
    const { selectedClub, filter } = this.state;
    return (
      <Fragment>
        <Helmet>
          <title>{STATS_SC_HISTORY_TITLE}</title>
          <meta name="description" content={STATS_SC_HISTORY_DESC} />
        </Helmet>
        <HeaderFilterView
          scores={scores}
          handleChangeFilter={this.handleChangeFilter}
          handleChangeClub={this.handleChangeClub}
          viewAvgScorePerHole={this.viewAvgScorePerHole}
        />
        {fetchStatus === jobStatus.SUCCESS ? (
          <ContentList
            showSumHeader
            filter={filter}
            scores={scores}
            recentScores={recentScores}
            deletedScores={deletedScores}
            revisionScores={revisionScores}
            additionalData={additionalData}
            selectedClub={selectedClub}
            notFoundText="No scores found"
            scores9Holes={scores9Holes}
            onEditRoundStats={this.onEditOrViewRoundStats}
            onViewRoundStats={this.onEditOrViewRoundStats}
          />
        ) : (
          <Loader />
        )}
        <LoadMore
          onLoadMore={this.fetchMoreScores}
          offset={offset}
          fetchStatus={fetchNextStatus}
        />
      </Fragment>
    );
  }
}

ScoreHistory.propTypes = {
  getStatsScores: PropTypes.func.isRequired,
  fetchStatsScoresNext: PropTypes.func.isRequired,
  fetchStatus: PropTypes.string,
  fetchNextStatus: PropTypes.bool.isRequired,
  offset: PropTypes.number.isRequired,
  scores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  scores9Holes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  recentScores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deletedScores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  revisionScores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  additionalData: PropTypes.shape({}),
};

ScoreHistory.defaultProps = {
  additionalData: undefined,
};

const mapStateToProps = ({ statsReducer, profileReducer }) => ({
  scores: statsReducer.scoresReducer.scores,
  golferActive: profileReducer.golferProfileFetchReducer.golferActive,
  scores9Holes: statsReducer.scoresReducer.scores9Holes,
  recentScores: statsReducer.scoresReducer.recentScores,
  deletedScores: statsReducer.scoresReducer.deletedScores,
  revisionScores: statsReducer.scoresReducer.revisionScores,
  offset: statsReducer.scoresReducer.offset,
  fetchStatus: statsReducer.scoresReducer.jobStatus,
  fetchNextStatus: statsReducer.scoresReducer.fetchNextStatus,
  additionalData: statsReducer.scoresReducer.additionalData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getStatsScores, fetchStatsScoresNext }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScoreHistory);
