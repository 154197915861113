import { checkApiError } from "../../auth/actions/api-login-methods";

export const POST_EDIT_ROUND_STATS = "POST_EDIT_ROUND_STATS";
export const DELETE_ROUND_STATS = "DELETE_ROUND_STATS";
export const POST_EDIT_ROUND_STATS_FAILED = "POST_EDIT_ROUND_STATS_FAILED";
export const POST_EDIT_ROUND_STATS_SUCCESS = "POST_EDIT_ROUND_STATS_SUCCESS";
export const POST_EDIT_ROUND_STATS_RESET = "POST_EDIT_ROUND_STATS_RESET";

export const postEditRoundStats = (score, roundStats) => ({
  type: POST_EDIT_ROUND_STATS,
  payload: { score: score, roundStats: roundStats },
});

export const deleteRoundStats = (score) => ({
  type: DELETE_ROUND_STATS,
  payload: { score: score, params: { only_advanced_stats: true } },
});

export function postEditRoundStatsFailed(error) {
  return (dispatch) => {
    dispatch({ type: POST_EDIT_ROUND_STATS_FAILED });
    dispatch(checkApiError(error));
  };
}

export const postEditRoundStatsSuccess = (payload) => ({
  type: POST_EDIT_ROUND_STATS_SUCCESS,
  payload,
});

export const postEditRoundStatsReset = (payload) => ({
  type: POST_EDIT_ROUND_STATS_RESET,
  payload,
});
