import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { findExactRoute } from "../../../../shared/helpers/route-helper";
import { routesContext } from "../../../../shared/routes/routes-context";
import SelectCourse from "../../../select-course/components/SelectCourse";
import { manualCourse } from "../../variables/post-score-variables";
import { Helmet } from "react-helmet";
import {
  SELECT_COURSE_POST_TOTAL_TITLE,
  SELECT_COURSE_POST_TOTAL_DESC,
  SELECT_COURSE_POST_HBH_TITLE,
  SELECT_COURSE_POST_HBH_DESC,
} from "../../../../shared/variables/meta-seo";

class SelectCourses extends Component {
  static contextType = routesContext;
  isTotalScore = () => {
    const route = findExactRoute(this.props.match.path, this.context);
    return route.path === this.context.postScoreTotalSelectCourse.path;
  };
  getTotalScoreMetaTags = () => {
    return (
      <Helmet>
        <title>{SELECT_COURSE_POST_TOTAL_TITLE}</title>
        <meta name="description" content={SELECT_COURSE_POST_TOTAL_DESC} />
      </Helmet>
    );
  };
  getHbhScoreMetaTags = () => {
    return (
      <Helmet>
        <title>{SELECT_COURSE_POST_HBH_TITLE}</title>
        <meta name="description" content={SELECT_COURSE_POST_HBH_DESC} />
      </Helmet>
    );
  };

  getNavLink = () =>
    this.isTotalScore()
      ? this.context.postScoreTotalPost.path
      : this.context.postScoreHbhRoundSetup.path;

  getTabLinkMyCourses = () =>
    this.isTotalScore()
      ? this.context.postScoreTotalMyCourses.path
      : this.context.postScoreHbhMyCourses.path;

  getTabLinkPostScore = () =>
    this.isTotalScore()
      ? this.context.postScoreTotalSelectCourse.path
      : this.context.postScoreHbhSelectCourse.path;

  render = () => {
    return (
      <Fragment>
        {this.isTotalScore()
          ? this.getTotalScoreMetaTags()
          : this.getHbhScoreMetaTags()}
        <div className="container">
          <section className="post-score">
            <SelectCourse
              {...this.props}
              hasManuallyInput={this.isTotalScore()}
              navItemLink={this.getNavLink()}
              tabLinkMyCourses={this.getTabLinkMyCourses}
              tabLinkPostCourse={this.getTabLinkPostScore}
              onManuallyInputCourse={() =>
                this.isTotalScore()
                  ? this.context.postScoreTotalPost.navigate(manualCourse)
                  : this.context.postScoreHbhRoundSetup.navigate(manualCourse)
              }
            />
          </section>
        </div>
      </Fragment>
    );
  };
}

SelectCourses.propTypes = {
  match: PropTypes.object.isRequired,
};

export default SelectCourses;
