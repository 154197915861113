import { checkApiError } from "../../auth/actions/api-login-methods";

export const FETCH_COURSES = "FETCH_COURSES";
export const FETCH_COURSES_FAILED = "FETCH_COURSES_FAILED";
export const FETCH_COURSES_SUCCESS = "FETCH_COURSES_SUCCESS";
export const FETCH_COURSES_RECENT_SCORES_SUCCESS =
  "FETCH_COURSES_RECENT_SCORES_SUCCESS";
export const FETCH_COURSES_RECENT_SCORES_FAILED =
  "FETCH_COURSES_RECENT_SCORES_FAILED";

export const RESET_ALL_COURSES = "RESET_ALL_COURSES";

export const fetchCourses = (searchPhrase, country, state) => ({
  type: FETCH_COURSES,
  payload: { searchPhrase: searchPhrase, country: country, state: state },
});

export const fetchRecentCourses = () => ({
  type: FETCH_COURSES,
  payload: { mostRecent: true },
});

export function fetchCoursesFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_COURSES_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchCoursesSuccess = (payload) => ({
  type: FETCH_COURSES_SUCCESS,
  payload,
});

export const fetchCoursesRecentScoresSuccess = (payload) => ({
  type: FETCH_COURSES_RECENT_SCORES_SUCCESS,
  payload,
});

export function fetchCoursesRecentScoresFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_COURSES_RECENT_SCORES_FAILED });
    dispatch(checkApiError(error));
  };
}

export const resetAllCourses = () => ({
  type: RESET_ALL_COURSES,
});
