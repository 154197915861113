import { checkApiError } from "../../auth/actions/api-login-methods";

export const FETCH_GOLFER = "FETCH_GOLFER";
export const FETCH_GOLFER_FAILED = "FETCH_GOLFER_FAILED";
export const FETCH_GOLFER_SUCCESS = "FETCH_GOLFER_SUCCESS";

export const fetchGolfer = (ghin_number, clubId) => ({
  type: FETCH_GOLFER,
  payload: { ghin_number, clubId },
});

export function fetchGolferFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_GOLFER_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchGolferSuccess = (payload) => ({
  type: FETCH_GOLFER_SUCCESS,
  payload,
});
