import { isEmpty } from "lodash";

export const getGolferId = (golfer) => {
  const id = golfer.ghin_number
    ? golfer.ghin_number
    : golfer.id || golfer.ghin || golfer.manual_golfer_id;
  return parseInt(id, 10);
};

export const getGolferGender = (golfer) => golfer.gender || golfer.gender;

export const filterGhinMemberships = (memberships) => {
  const activeMemberships = memberships?.filter(
    (m) => m.status === "Active" && m.technology_provider === "GHIN"
  );
  if (activeMemberships.length > 0) {
    return activeMemberships.sort((a, b) =>
      a.club_name.localeCompare(b.club_name)
    );
  } else {
    return memberships;
  }
};

export const hasActiveMembership = (memberships) => {
  const activeMemberships = memberships?.filter(
    (m) => m.status === "Active" && m.technology_provider === "GHIN"
  );
  if (activeMemberships.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const hasTrialMembership = (memberships) => {
  const trialMemberships = memberships?.filter(
    (m) => m.is_trial === true && m.technology_provider === "GHIN"
  );
  if (trialMemberships.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const getCapMessage = (golfer) => {
  if (!isEmpty(golfer?.message_club_authorized))
    return golfer?.message_club_authorized;

  const softCap = golfer.soft_cap;
  const hardCap = golfer.hard_cap;
  if ((softCap === "true" && hardCap === "true") || hardCap === "true")
    return `A Hard Cap has been applied to this Handicap Index, which restricts upward movement to 5.0 strokes over the Low Handicap Index (in effect when the score was posted) after the application of the Soft Cap.`;
  if (softCap === "true") {
    return "A Soft Cap has been applied to this Handicap Index, which suppresses upward movement by 50% after a 3.0 stroke increase over the Low Handicap Index (in effect when the score was posted) has been reached.";
  }
  return null;
};
