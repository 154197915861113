import {
  FETCH_CONTACT_ASSOCIATIONS,
  fetchContactAssociationsFailed,
  fetchContactAssociationsSuccess,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import AssociationsApi from "../../../services/associations-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_CONTACT_ASSOCIATIONS))
    .pipe(switchMap(({ payload }) => getAssociations(payload)));

const getAssociations = (payload) =>
  from(AssociationsApi.getGolferAssociations(payload.ghinNumber))
    .pipe(
      map((response) =>
        fetchContactAssociationsSuccess({
          associations: response.data.Associations,
          memberships: payload.memberships,
        })
      )
    )
    .pipe(catchError((error) => of(fetchContactAssociationsFailed(error))));
