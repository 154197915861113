import {
  FETCH_GOLFER_PROFILE_HANDICAP_LOWHI,
  fetchGolferProfileHandicapLowhiSuccess,
  fetchGolferProfileHandicapLowhiFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_GOLFER_PROFILE_HANDICAP_LOWHI))
    .pipe(switchMap(({ payload }) => getGolferHandicap(payload)));

const getGolferHandicap = (payload) =>
  from(GolferApi.getHandicapHistoryLowhi(payload))
    .pipe(
      map((response) =>
        fetchGolferProfileHandicapLowhiSuccess(response.data.golfers)
      )
    )
    .pipe(catchError(() => of(fetchGolferProfileHandicapLowhiFailed(payload))));
