import { checkApiError } from "../../auth/actions/api-login-methods";
import ScoreType from "../../../shared/variables/stats-score-type";

export const FETCH_2020_SCORES = "FETCH_2020_SCORES";
export const FETCH_2020_SCORES_FAILED = "FETCH_2020_SCORES_FAILED";
export const FETCH_2020_SCORES_SUCCESS = "FETCH_2020_SCORES_SUCCESS";

export const fetch2020Scores = () => ({
  type: FETCH_2020_SCORES,
  payload: { filter: ScoreType.YEAR },
});

export function fetch2020ScoresFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_2020_SCORES_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetch2020ScoresSuccess = (scores) => ({
  type: FETCH_2020_SCORES_SUCCESS,
  payload: { scores },
});
