export const FETCH_GOLFER_PROFILE_HANDICAP = "FETCH_GOLFER_PROFILE_HANDICAP";
export const FETCH_GOLFER_PROFILE_HANDICAP_FAILED =
  "FETCH_GOLFER_PROFILE_HANDICAP_FAILED";
export const FETCH_GOLFER_PROFILE_HANDICAP_SUCCESS =
  "FETCH_GOLFER_PROFILE_HANDICAP_SUCCESS";

export const fetchGolferProfileHandicap = (payload) => ({
  type: FETCH_GOLFER_PROFILE_HANDICAP,
  payload,
});

export const fetchGolferProfileHandicapFailed = () => ({
  type: FETCH_GOLFER_PROFILE_HANDICAP_FAILED,
});

export const fetchGolferProfileHandicapSuccess = (payload) => ({
  type: FETCH_GOLFER_PROFILE_HANDICAP_SUCCESS,
  payload,
});
