import authPaths from "../../../shared/routes/auth-paths";
import Navigate from "../../../shared/navigation/navigate";
import GolferApi from "../../../services/golfer-api";

import { resetHandicapCalculator } from "../../handicap-calculator/actions/calculator-golfers";
import { handicapCalculatorRemoveCourse } from "../../handicap-calculator/actions/calculator-course-fetch-actions";
import { resetFollowedGolfers } from "../../golfers/actions/followed-golfers-actions";
import { resetProductAlert } from "../../product-alert/actions/product-alert-fetch-actions";
import { clearHomeCourses } from "../actions/home-courses";
import { logoutGolferProfile } from "../../profile/actions/golfer-profile-fetch";

export const LOGOUT = "LOGOUT";

export function checkApiError(error = null) {
  return (dispatch) => {
    if (error && error.message && error.message.includes("401")) {
      dispatch(completeLogout()).then(() => {
        Navigate.resetToPath(authPaths.login.path);
      });
    } else {
      dispatch({ type: "" });
    }
  };
}

const logout = () => ({ type: LOGOUT });

export function completeLogout() {
  return (dispatch) => {
    GolferApi.logout();
    return new Promise((resolve) => {
      dispatch(handicapCalculatorRemoveCourse());
      dispatch(resetHandicapCalculator());
      dispatch(resetFollowedGolfers());
      dispatch(clearHomeCourses());
      dispatch(resetProductAlert());
      dispatch(logoutGolferProfile());
      dispatch(logout());
      setTimeout(() => {
        resolve();
      }, 250);
    });
  };
}
