import React, { Component, Fragment } from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";

import { routesContext } from "../../shared/routes/routes-context";

import PromoBanner from "../../shared/components/banners/PromoBanner";

export default class GolferLookup extends Component {
  static contextType = routesContext;

  renderSwitch() {
    return (
      <Switch>
        <Redirect
          exact
          from={this.context.golferLookup.path}
          to={this.context.golferLookupFollowing.path}
        />
        <Route {...this.context.golferLookupFollowing} />
        <Route {...this.context.golferLookupMyClubs} />
        <Route {...this.context.golferLookupAllGolfers} />
        <Route {...this.context.golfer} />
      </Switch>
    );
  }

  isGolferDetailsRoute() {
    const { location } = this.props;
    const route = this.context.golfer.path.replace(":id/club/:club_id", "");
    if (location && location.pathname && location.pathname.includes(route)) {
      return true;
    }
    return false;
  }

  render() {
    if (this.isGolferDetailsRoute()) return this.renderSwitch();
    return (
      <Fragment>
        <div className="container has-adds">
          <section className="golfer_lookup_section">
            <PromoBanner />
            <div className="section_header">
              <h1>Golfer Lookup</h1>
              <ul className="tabs rounded">
                <li>
                  <NavLink
                    to={this.context.golferLookupFollowing.path}
                    activeClassName="is-active"
                  >
                    Following
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={this.context.golferLookupMyClubs.path}
                    activeClassName="is-active"
                  >
                    My Clubs
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={this.context.golferLookupAllGolfers.path}
                    activeClassName="is-active"
                  >
                    All Golfers
                  </NavLink>
                </li>
              </ul>
            </div>
            {this.renderSwitch()}
          </section>
        </div>
      </Fragment>
    );
  }
}

GolferLookup.propTypes = {
  location: PropTypes.object.isRequired,
};
