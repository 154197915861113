import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import pagesPaths from "../../../shared/routes/pages-paths";

class Links extends Component {
  render() {
    return (
      <ul className="footer__links">
        <li>
          <a
            className="footer__link"
            href="http://www.usga.org/handicapping/ghin-faqs.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQs
          </a>
        </li>
        <li>
          <NavLink
            className="footer__link"
            activeClassName="is-active"
            to={pagesPaths.contactUs.path}
          >
            CONTACT&nbsp;
            <span aria-hidden="true">US</span>
            <span className="visually-hidden">us</span>
          </NavLink>
        </li>
        <li>
          <a
            className="footer__link"
            href="https://www.usga.org/content/usga/home-page/Handicap-ghin/ghin-privacy-policy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            PRIVACY POLICY
          </a>
        </li>
        <li>
          <a
            className="footer__link"
            href="https://www.usga.org/content/usga/home-page/Handicap-ghin/ghin-terms-of-service.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            TERMS OF SERVICE
          </a>
        </li>
        <li>
          <a
            className="footer__link"
            href="https://www.usga.org/content/usga/home-page/usga-accessibility-statement.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            ACCESSIBILITY
          </a>
        </li>
      </ul>
    );
  }
}
export default Links;
