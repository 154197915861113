import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { routesContext } from "../../../../shared/routes/routes-context";

class NavigationNotLoggedLinks extends Component {
  static contextType = routesContext;
  render() {
    return (
      <ul className="nav__links">
        <li>
          <NavLink
            activeClassName="is-active"
            className="nav__link bottom-border-focus"
            to={this.context.ghinAssociations.path}
          >
            GHIN ASSOCIATIONS
          </NavLink>
        </li>
        <li>
          <a
            className="nav__link bottom-border-focus"
            target="_blank"
            href="https://www.usga.org/content/usga/home-page/Handicap-ghin/GHIN-Sentry.html"
            rel="noopener noreferrer"
          >
            ABOUT GHIN
          </a>
        </li>
        <li>
          <NavLink
            activeClassName="is-active"
            className="nav__link bottom-border-focus"
            to={this.context.contactUs.path}
          >
            CONTACT&nbsp;
            <span aria-hidden="true">US</span>
            <span className="visually-hidden">us</span>
          </NavLink>
        </li>
        <li>
          <a
            href="https://www.usga.org/content/usga/home-page/get-handicap.html?utm_source=ghin&utm_medium=web&utm_campaign=gah"
            target="_blank"
            className="nav__link bottom-border-focus"
            rel="noopener noreferrer"
          >
            GET A HANDICAP INDEX®
          </a>
        </li>
        <li>
          <a
            href="http://www.usga.org/handicapping/ghin-faqs.html"
            target="_blank"
            className="nav__link bottom-border-focus"
            rel="noopener noreferrer"
          >
            FAQS
          </a>
        </li>
      </ul>
    );
  }
}

export default NavigationNotLoggedLinks;
