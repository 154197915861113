import { checkApiError } from "../../auth/actions/api-login-methods";

export const RECENT_SCORES_FETCH = "RECENT_SCORES_FETCH";
export const RECENT_SCORES_FETCH_FAILED = "RECENT_SCORES_FETCH_FAILED";
export const RECENT_SCORES_FETCH_SUCCESS = "RECENT_SCORES_FETCH_SUCCESS";

export const fetchRecentScores = (offset, limit) => ({
  type: RECENT_SCORES_FETCH,
  payload: { offset, limit },
});

export function fetchRecentScoresFailed(error) {
  return (dispatch) => {
    dispatch({ type: RECENT_SCORES_FETCH_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchRecentScoresSuccess = (payload) => ({
  type: RECENT_SCORES_FETCH_SUCCESS,
  payload,
});
