export const FETCH_CALCULATOR_COURSE = "FETCH_CALCULATOR_COURSE";
export const FETCH_CALCULATOR_COURSE_SUCCESS =
  "FETCH_CALCULATOR_COURSE_SUCCESS";
export const FETCH_CALCULATOR_REMOVE_COURSE = "FETCH_CALCULATOR_REMOVE_COURSE";

export const fetchCalculatorCourse = (courseId) => ({
  type: FETCH_CALCULATOR_COURSE,
  payload: { courseId: courseId },
});

export const fetchCalculatorCourseSuccess = (payload) => ({
  type: FETCH_CALCULATOR_COURSE_SUCCESS,
  payload,
});

export const handicapCalculatorRemoveCourse = () => ({
  type: FETCH_CALCULATOR_REMOVE_COURSE,
});
