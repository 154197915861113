import { checkApiError } from "../../auth/actions/api-login-methods";

export const CONFIRM_POST_SCORE = "CONFIRM_POST_SCORE";
export const CONFIRM_POST_SCORE_FAILED = "CONFIRM_POST_SCORE_FAILED";
export const CONFIRM_POST_SCORE_SUCCESS = "CONFIRM_POST_SCORE_SUCCESS";
export const CONFIRM_POST_SCORE_RESET = "CONFIRM_POST_SCORE_RESET";

export const confirmPostScore = (scoreId, golferId) => ({
  type: CONFIRM_POST_SCORE,
  payload: { scoreId: scoreId, golferId: golferId },
});

export function confirmPostScoreFailed(error) {
  return (dispatch) => {
    dispatch({ type: CONFIRM_POST_SCORE_FAILED });
    dispatch(checkApiError(error));
  };
}

export const confirmPostScoreSuccess = (payload) => ({
  type: CONFIRM_POST_SCORE_SUCCESS,
  payload,
});

export const confirmPostScoreReset = (payload) => ({
  type: CONFIRM_POST_SCORE_RESET,
  payload,
});
