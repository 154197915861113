import React, { Component, Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { routesContext } from "../../../../shared/routes/routes-context";

export default class PostScore extends Component {
  static contextType = routesContext;
  render = () => (
    <Fragment>
      <Switch>
        <Redirect
          exact
          from={this.context.postScore.path}
          to={this.context.postScoreTotal.path}
        />
        <Route {...this.context.postScoreTotal} />
        <Route {...this.context.postScoreHbh} />
      </Switch>
    </Fragment>
  );
}

PostScore.propTypes = {
  match: PropTypes.object.isRequired,
};
