import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";
import moment from "moment/moment";
import Navigate from "../../../../shared/navigation/navigate";
import ScoresApi from "../../../../services/scores-api";
import { routesContext } from "../../../../shared/routes/routes-context";

class DeleteRoundConfirmationModal extends Component {
  static contextType = routesContext;
  constructor(props) {
    super(props);

    this.state = {
      deleteBtnDisabled: false,
      errorMessage: "",
      displayError: false,
    };
  }

  onClose = () => {
    const { onClose } = this.props;
    this.setState({
      errorMessage: "",
      displayError: false,
      deleteBtnDisabled: false,
    });
    onClose();
  };

  onConfirm = () => {
    this.setState({
      deleteBtnDisabled: true,
    });
    ScoresApi.deleteScore(this.props.score.id)
      .then(() => {
        if (this.props.isTrialGolfer)
          Navigate.resetToPath(this.context.profile.path);
        else Navigate.resetToPath(this.context.statsScoreHistory.path);
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.response.data.error,
          displayError: true,
          deleteBtnDisabled: false,
        });
      });
  };

  render() {
    const { score, isOpen, isHbhScoreWithAdvStats } = this.props;
    const playedAt = moment(score.played_at).format("MM/DD/YYYY");

    return (
      <Fragment>
        <YesNoModal
          onConfirm={this.onConfirm}
          onAbort={this.onClose}
          isOpen={isOpen}
          yesTitle="Delete Round"
          yesDisabled={this.state.deleteBtnDisabled}
          noTitle="Nevermind"
          reversedOrder={true}
          learnMoreLink={
            "https://www.usga.org/content/usga/home-page/handicapping/world-handicap-system/world-handicap-system-usga-golf-faqs/faqs---acceptable-for-handicap-purposes.html"
          }
          className="delete-round-modal"
        >
          <p className="delete-round-modal-header">
            {score.adjusted_gross_score} ·{" "}
            {score.is_manual ? score.course_display_value : score.facility_name}{" "}
            · {playedAt}
          </p>
          <p style={{ marginBottom: "15px", marginTop: "25px" }}>
            <span className="delete-round-alert">
              <span className="icon" />
            </span>
          </p>
          <p className="delete-round-modal-text-1">
            To update this score, you’ll need to delete this round and post a
            new one.
          </p>
          <p
            className="delete-round-modal-text-2"
            style={{ marginBottom: isHbhScoreWithAdvStats ? "15px" : "" }}
          >
            For handicapping purposes, all acceptable scores should stay in your
            record. Please delete this round only if you need to correct an
            error.
          </p>
          {isHbhScoreWithAdvStats && (
            <p className="delete-round-note">
              <span>Note:</span> You will also lose the stats you entered for
              this round.
            </p>
          )}
          {this.state.displayError && (
            <span className="error" style={{ fontWeight: "500" }}>
              {this.state.errorMessage}
            </span>
          )}
        </YesNoModal>
      </Fragment>
    );
  }
}

DeleteRoundConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteRoundConfirmationModal;
