import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink } from "react-router-dom";
import { routesContext } from "../../../../shared/routes/routes-context";
import Select from "react-select";
import {
  fetchCalculatorCourse,
  handicapCalculatorInit,
  setNumberOfHoles,
  resetAddedGolfersCount,
  handicapCalculatorRemoveCourse,
  removeAllGolfers,
  setHandicapAllowance,
  fetchPlayingHandicaps,
  setInitialTeeSetId,
  addGolfersManualIdReference,
} from "../../actions";
import Loader from "../../../../shared/components/loaders/Loader";
import GolferItem from "./GolferItem";
import noOfHoles from "../../../../shared/variables/number-of-holes";
import {
  getCourseName,
  getGolfersWithPlayingHandicaps,
  sortGolfersByLastName,
  getHandicapAllowanceOptions,
} from "../../../../shared/helpers/handicap-calculator-helper";
import { getGolferId } from "../../../../shared/helpers/user-helper";
import HandicapInfoModal from "../../../../shared/components/modals/HandicapInfoModal";
import { hasEighTeenHoles } from "../../../post-score/helpers/round-setup-helper";
import NoActiveTeesModal from "./NoActiveTeesModal";
import RemoveAllGolfersModal from "./RemoveAllGolfersModal";
import {
  hasGenderTeeSets,
  hasGenderSlopeRating,
} from "../../../post-score/helpers/round-setup-helper";
import { getUserGender } from "../../../../shared/helpers/user-helper";
import NoSlopeRatingModal from "../../../../shared/components/modals/NoSlopeRatingModal";
import PlayingHandicapModal from "../../../../shared/components/modals/PlayingHandicapModal";
import ShotsOffModal from "../../../../shared/components/modals/ShotsOffModal";
import HandicapAllowanceModal from "../../../../shared/components/modals/HandicapAllowanceModal";
import { Helmet } from "react-helmet";
import {
  H_CALC_TITLE,
  H_CALC_DESC,
} from "../../../../shared/variables/meta-seo";

const options = getHandicapAllowanceOptions();

class Calculator extends Component {
  static contextType = routesContext;
  constructor(props) {
    super(props);
    this.state = {
      courseHandicapModalOpen: false,
      noActiveTeesModalOpen: false,
      noSlopeRatingModalOpen: false,
      removeAllGolfersModalOpen: false,
      playingHandicapModalOpen: false,
      shotsOffModalOpen: false,
      handicapAllowanceModalOpen: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { course, teeSetsFetchComplete, golfers, allowance } = nextProps;
    if (course && course !== this.props.course) {
      const numberOfHoles = !hasEighTeenHoles(course)
        ? noOfHoles.NINE
        : noOfHoles.EIGHTEEN;
      this.props.setNumberOfHoles(numberOfHoles);
      const gender = getUserGender();
      const teeSets = hasGenderTeeSets(course, gender);
      if (!teeSets) {
        this.setState({ noActiveTeesModalOpen: true });
      }

      const slopeRating = hasGenderSlopeRating(course, gender);
      if (teeSets && !slopeRating) {
        this.setState({ noSlopeRatingModalOpen: true });
      }
    }
    if (
      teeSetsFetchComplete &&
      allowance &&
      allowance === this.props.allowance
    ) {
      this.props.addGolfersManualIdReference();
      setTimeout(() => {
        this.fetchPlayingHandicaps(this.selectBackSide(golfers));
      }, 100);
    }
  }

  componentDidMount() {
    const {
      course,
      match: {
        params: { courseId },
      },
    } = this.props;
    if (!course || (course && course.CourseId !== parseInt(courseId, 10))) {
      const { handicapCalculatorInit, fetchCalculatorCourse } = this.props;
      fetchCalculatorCourse(courseId);
      handicapCalculatorInit(
        courseId,
        this.props.currentUser,
        this.props.golfers
      );
    }
    const teeSetId = new URLSearchParams(window.location.search).get(
      "teeSetId"
    );
    if (teeSetId) {
      this.props.setInitialTeeSetId(teeSetId);
    }
    this.props.resetAddedGolfersCount();
  }

  fetchPlayingHandicaps(golfers) {
    const parsedGolfers = this.selectBackSide(golfers);
    this.props.fetchPlayingHandicaps(
      getGolfersWithPlayingHandicaps(parsedGolfers)
    );
  }

  // front not present, it will get the back side
  selectBackSide(golfers) {
    const checkedGolfers = golfers.map((g) => {
      if (!g.selected_tee_set) return g;
      const selected_tee_data = g.selected_tee_set.split("_");
      if (selected_tee_data[1] == "F9") {
        const tee_set = g.tee_sets.filter(
          (t) => `${t.tee_set_id}` === selected_tee_data[0]
        )[0];
        const has_front = tee_set.ratings.filter((r) => r.tee_set_side == "F9");
        if (has_front.length === 0)
          g.selected_tee_set = `${selected_tee_data[0]}_B9`;
      }
      return g;
    });
    return checkedGolfers;
  }

  changeNumberOfHoles = (noOfHoles) => this.props.setNumberOfHoles(noOfHoles);

  onChangeAllowance = (selection) =>
    this.props.setHandicapAllowance(selection.value);

  renderAddGolfer() {
    const { course } = this.props;
    return (
      <div className="panel">
        <div className="btn-wrapper">
          <NavLink
            className="btn fill cardinal auto"
            to={this.context.handicapCalculatorAddGolfer.path.replace(
              ":courseId",
              course.CourseId
            )}
          >
            Add Golfers
          </NavLink>
        </div>
      </div>
    );
  }

  renderHeader() {
    const { course, numberOfHoles, allowance } = this.props;
    const hasEighTeen = hasEighTeenHoles(course);
    return (
      <div className="header_club_selected">
        <h4>{getCourseName(course)}</h4>
        <NavLink
          className="selected__control"
          to={this.context.handicapCalculatorSelectCourse.path}
        >
          Change
        </NavLink>
        <ul className="tabs rounded auto">
          {!!hasEighTeen && (
            <li>
              <NavLink
                to="#"
                onClick={() => this.changeNumberOfHoles(noOfHoles.EIGHTEEN)}
                className={
                  numberOfHoles === noOfHoles.EIGHTEEN ? "is-active" : ""
                }
              >
                18 Holes
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              to="#"
              onClick={() => this.changeNumberOfHoles(noOfHoles.NINE)}
              className={numberOfHoles === noOfHoles.NINE ? "is-active" : ""}
            >
              9 Holes
            </NavLink>
          </li>
        </ul>
        <div className="handicap-allowance__container">
          <label>Handicap Allowance</label>
          <button
            onClick={() => this.setState({ handicapAllowanceModalOpen: true })}
            className="btn blank"
            style={{ padding: "0" }}
          >
            <i
              style={{ color: "#00365f", fontSize: "22px" }}
              className="material-icons-outlined"
            >
              info
            </i>
          </button>
          <Select
            aria-label="Handicap Allowance"
            value={{ value: allowance, label: `${allowance}%` }}
            className={"default_select tees_select"}
            classNamePrefix={"ds"}
            onChange={(selection) => this.onChangeAllowance(selection)}
            options={options}
          />
        </div>
      </div>
    );
  }

  onNoActiveTeesModalClose = () => {
    this.setState({ noActiveTeesModalOpen: false });
    this.props.handicapCalculatorRemoveCourse();
  };

  noSlopeRatingModalClose = () => {
    this.setState({ noSlopeRatingModalOpen: false });
    this.props.handicapCalculatorRemoveCourse();
  };

  onPressRemoveGolfers = () =>
    this.setState({ removeAllGolfersModalOpen: true });

  onAbortRemoveGolfers = () =>
    this.setState({ removeAllGolfersModalOpen: false });

  onConfirmRemoveGolfers = () => {
    this.onAbortRemoveGolfers();
    this.props.removeAllGolfers(this.props.currentUser.ghin_number);
  };

  render() {
    const { course, numberOfHoles } = this.props;
    if (!course) return <Loader />;

    const {
      noActiveTeesModalOpen,
      courseHandicapModalOpen,
      noSlopeRatingModalOpen,
      removeAllGolfersModalOpen,
    } = this.state;
    const { allowance, golfers } = this.props;
    const sortedGolfers = sortGolfersByLastName(this.selectBackSide(golfers));
    return (
      <Fragment>
        <Helmet>
          <title>{H_CALC_TITLE}</title>
          <meta name="description" content={H_CALC_DESC} />
        </Helmet>
        <h1>Handicap Calculator</h1>
        {this.renderHeader()}
        <div className="panel">
          <div className="golfer_list">
            <h4>
              Golfers
              {sortedGolfers.length > 1 && (
                <div className="rightView">
                  <button
                    className="btn blank medium no-pad"
                    onClick={this.onPressRemoveGolfers}
                  >
                    Remove All Golfers
                  </button>
                </div>
              )}
            </h4>
            {/* Table 2 - with toggle header*/}
            <div className="hide-on-tablet">
              <div className="chc_row_c tbl tbl--handicap-calc">
                <div className="tbl-row tbl--handicap-calc">
                  <div className="tbl-cell header grow golfer-name-cell">
                    Name
                  </div>
                  <div className="tbl-cell header grow tees-select">
                    <span>Tees</span>
                  </div>
                  <div className="tbl-cell header text-center handicap-index">
                    Handicap Index
                  </div>
                  <div className="tbl-cell header text-center course-handicap">
                    <span>Course Handicap</span>
                    <sup className="trigger-container">
                      <button
                        onClick={() =>
                          this.setState({ courseHandicapModalOpen: true })
                        }
                        className="btn blank"
                      >
                        <i
                          className="material-icons-outlined"
                          style={{ color: "#00365f", fontSize: "22px" }}
                        >
                          info
                        </i>
                      </button>
                    </sup>
                  </div>
                  <div className="tbl-cell header text-center playing-handicap">
                    <span>Playing Handicap</span>
                    <sup className="trigger-container">
                      <button
                        onClick={() =>
                          this.setState({ playingHandicapModalOpen: true })
                        }
                        className="btn blank"
                      >
                        <i
                          className="material-icons-outlined"
                          style={{ color: "#00365f", fontSize: "22px" }}
                        >
                          info
                        </i>
                      </button>
                    </sup>
                  </div>
                  <div className="tbl-cell header text-center shots-off">
                    <span>Shots Off</span>
                    <sup className="trigger-container">
                      <button
                        onClick={() =>
                          this.setState({ shotsOffModalOpen: true })
                        }
                        className="btn blank"
                      >
                        <i
                          className="material-icons-outlined"
                          style={{ color: "#00365f", fontSize: "22px" }}
                        >
                          info
                        </i>
                      </button>
                    </sup>
                  </div>
                </div>
              </div>
            </div>

            <div className="chc_row_c tbl phone__column small-cap">
              {sortedGolfers.map((golfer, index) => (
                <GolferItem
                  golfer={golfer}
                  numberOfHoles={numberOfHoles}
                  key={`${getGolferId(golfer)}-${course.CourseId}`}
                  course={course}
                  allowance={allowance}
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
        {this.renderAddGolfer()}
        <HandicapInfoModal
          isOkVisible={true}
          isOpen={courseHandicapModalOpen}
          onAbort={() => this.setState({ courseHandicapModalOpen: false })}
        />
        <NoActiveTeesModal
          isOpen={noActiveTeesModalOpen}
          onClose={this.onNoActiveTeesModalClose}
        />
        <RemoveAllGolfersModal
          isOpen={removeAllGolfersModalOpen}
          onAbort={this.onAbortRemoveGolfers}
          onConfirm={this.onConfirmRemoveGolfers}
        />
        <NoSlopeRatingModal
          message="The selected course is a Short Course. Tees do not include a Slope Rating."
          isOpen={noSlopeRatingModalOpen}
          onClose={this.noSlopeRatingModalClose}
        />
        <PlayingHandicapModal
          isOkVisible={true}
          isOpen={this.state.playingHandicapModalOpen}
          onAbort={() => this.setState({ playingHandicapModalOpen: false })}
        />
        <ShotsOffModal
          isOkVisible={true}
          isOpen={this.state.shotsOffModalOpen}
          onAbort={() => this.setState({ shotsOffModalOpen: false })}
        />
        <HandicapAllowanceModal
          isOkVisible={true}
          isOpen={this.state.handicapAllowanceModalOpen}
          onAbort={() => this.setState({ handicapAllowanceModalOpen: false })}
        />
      </Fragment>
    );
  }
}

Calculator.propTypes = {
  course: PropTypes.object,
  numberOfHoles: PropTypes.string,
  match: PropTypes.object.isRequired,
  fetchCalculatorCourse: PropTypes.func.isRequired,
  handicapCalculatorInit: PropTypes.func.isRequired,
  setNumberOfHoles: PropTypes.func.isRequired,
  resetAddedGolfersCount: PropTypes.func.isRequired,
  handicapCalculatorRemoveCourse: PropTypes.func.isRequired,
  removeAllGolfers: PropTypes.func.isRequired,
  setInitialTeeSetId: PropTypes.func.isRequired,
  golfers: PropTypes.array.isRequired,
};

Calculator.defaultProps = {
  course: undefined,
  numberOfHoles: noOfHoles.EIGHTEEN,
};

const mapStateToProps = ({ handicapCalculatorReducer, authReducer }) => ({
  course: handicapCalculatorReducer.calculatorCourseFetchReducer.course,
  golfers: handicapCalculatorReducer.calculatorGolfersReducer.golfers,
  allowance: handicapCalculatorReducer.calculatorGolfersReducer.allowance,
  teeSetsFetchComplete:
    handicapCalculatorReducer.calculatorGolfersReducer
      .golfersTeeSetsFetchComplete,
  numberOfHoles:
    handicapCalculatorReducer.calculatorGolfersReducer.numberOfHoles,
  currentUser: authReducer.loginReducer.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCalculatorCourse,
      handicapCalculatorInit,
      setNumberOfHoles,
      resetAddedGolfersCount,
      handicapCalculatorRemoveCourse,
      removeAllGolfers,
      setHandicapAllowance,
      fetchPlayingHandicaps,
      setInitialTeeSetId,
      addGolfersManualIdReference,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Calculator);
