export const CONTACT_PROFILE_FETCH = "CONTACT_PROFILE_FETCH";
export const CONTACT_PROFILE_FETCH_FAILED = "CONTACT_PROFILE_FETCH_FAILED";
export const CONTACT_PROFILE_FETCH_SUCCESS = "CONTACT_PROFILE_FETCH_SUCCESS";

export const fetchContactProfile = (payload) => ({
  type: CONTACT_PROFILE_FETCH,
  payload,
});

export const fetchContactProfileFailed = () => ({
  type: CONTACT_PROFILE_FETCH_FAILED,
});

export function fetchContactProfileSuccess(payload) {
  return (dispatch) => {
    dispatch({ type: CONTACT_PROFILE_FETCH_SUCCESS, payload });
  };
}
