import { checkApiError } from "../../auth/actions/api-login-methods";

export * from "./followed-golfers-actions";
export const FETCH_FOLLOWED_GOLFERS_FAILED = "FETCH_FOLLOWED_GOLFERS_FAILED";
export const FOLLOW_GOLFER_FAILED = "FOLLOW_GOLFER_FAILED";
export const UN_FOLLOW_GOLFER_FAILED = "UN_FOLLOW_GOLFER_FAILED";

export function fetchFollowedGolfersFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_FOLLOWED_GOLFERS_FAILED });
    dispatch(checkApiError(error));
  };
}

export function followGolferFailed(error) {
  return (dispatch) => {
    dispatch({ type: FOLLOW_GOLFER_FAILED });
    dispatch(checkApiError(error));
  };
}

export function unFollowGolferFailed(error) {
  return (dispatch) => {
    dispatch({ type: UN_FOLLOW_GOLFER_FAILED });
    dispatch(checkApiError(error));
  };
}
