import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Navigate from "../../../../shared/navigation/navigate";
import DefaultCustomSelect from "../../../../shared/components/selects/DefaultCustomSelect";
import InputField from "./Components/InputField";
import LoaderButton from "../../../../shared/components/buttons/LoaderButton";
import jobStatus from "../../../../shared/variables/job-status";
import { createProfile } from "../../actions";
import AuthErrorModal from "../common/AuthErrorModal";
import SuccessBody from "./Components/SuccessBody";
import { Helmet } from "react-helmet";
import {
  CREATE_PROFILE_TITLE,
  CREATE_PROFILE_DESC,
} from "../../../../shared/variables/meta-seo";
const DEFAULT_OPTIONS = [
  {
    value: "email",
    label: "Email Address",
  },
  {
    value: "ghin_number",
    label: "GHIN Number",
  },
  {
    value: "last_name",
    label: "Last Name",
  },
];
class CreateProfile extends Component {
  state = {
    firstValue: undefined,
    firstSelectOption: undefined,
    firstSelectOptions: DEFAULT_OPTIONS,
    secondValue: undefined,
    secondSelectOption: undefined,
    secondSelectOptions: DEFAULT_OPTIONS,
    failedModalOpen: false,
    displaySuccessBody: false,
    userProperties: undefined,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { postStatus } = nextProps;
    if (
      postStatus !== this.props.postStatus &&
      postStatus === jobStatus.FAILED
    ) {
      this.setState({ failedModalOpen: true });
    }

    if (
      postStatus !== this.props.postStatus &&
      postStatus === jobStatus.SUCCESS
    ) {
      this.setState({ displaySuccessBody: true });
    }
  }

  handleChangeOption = (option, field, oppositeField) => {
    this.removeOptionFromSelect(option.value, `${oppositeField}SelectOptions`);
    this.setState({
      [`${field}SelectOption`]: option,
      [`${field}Value`]: "",
    });
  };

  removeOptionFromSelect = (value, select) =>
    this.setState({
      [select]: DEFAULT_OPTIONS.filter((o) => o.value !== value),
    });

  onChangeValue = (value, field, isValid) =>
    this.setState({ [field]: value, [`${field}IsValid`]: isValid });

  onCancel = () => Navigate.toPath("/");

  onSubmit = () => {
    this.setState({ userProperties: this.getSubmitValues() });
    this.props.createProfile(this.getSubmitValues());
  };

  getSubmitValues() {
    const { firstValue, secondValue, firstSelectOption, secondSelectOption } =
      this.state;
    const fieldValue = function (option, value) {
      return { [option.value]: value };
    };
    return {
      ...fieldValue(firstSelectOption, firstValue),
      ...fieldValue(secondSelectOption, secondValue),
    };
  }

  renderFormContent() {
    const {
      firstValue,
      firstValueIsValid,
      firstSelectOption,
      firstSelectOptions,
      secondValue,
      secondValueIsValid,
      secondSelectOption,
      secondSelectOptions,
    } = this.state;
    const { postStatus } = this.props;
    const link = <a href="/contact-us">Golf Association</a>;
    const addMtClass = firstSelectOption?.value ? "" : "mt";

    return (
      <div className="create-profile">
        <div className="top-label padding">
          You will need to enter two of the following three pieces of
          information to verify your GHIN account:{" "}
        </div>
        <br />
        <DefaultCustomSelect
          onChange={(value) =>
            this.handleChangeOption(value, "first", "second")
          }
          value={firstSelectOption}
          options={firstSelectOptions}
          title="Select"
          placeholder="Select"
          className="default_select full_on_phone svg-color"
          aria-label="Select"
        />
        <InputField
          inputName="firstValue"
          inputType={firstSelectOption?.value}
          inputValue={firstValue}
          onChangeValue={this.onChangeValue}
        />
        <div className={`separator ${addMtClass}`} />
        <DefaultCustomSelect
          onChange={(value) =>
            this.handleChangeOption(value, "second", "first")
          }
          value={secondSelectOption}
          options={secondSelectOptions}
          title="Select"
          placeholder="Select"
          className="default_select full_on_phone svg-color"
          aria-label="Select"
        />
        <InputField
          inputName="secondValue"
          inputType={secondSelectOption?.value}
          inputValue={secondValue}
          onChangeValue={this.onChangeValue}
        />
        {!firstSelectOption && <span className="field-place-holder" />}
        {!secondSelectOption && <span className="field-place-holder" />}
        <div className="bottom-actions">
          <button className="btn blank blue" onClick={this.onCancel}>
            Cancel
          </button>
          <LoaderButton
            className="btn fill cardinal"
            onClick={this.onSubmit}
            disabled={!(firstValueIsValid && secondValueIsValid)}
            loading={String(postStatus === jobStatus.PROCESSING)}
          >
            VERIFY
          </LoaderButton>
        </div>

        <div className="bottom-link center">
          If you are having trouble verifying your GHIN account, please contact
          your Club or {link}.
        </div>
      </div>
    );
  }

  renderSuccessMessage() {
    const { userProperties } = this.state;
    const { successMessage } = this.props;
    return (
      <SuccessBody
        onBackToLogin={this.onCancel}
        message={successMessage}
        userProperties={userProperties}
      />
    );
  }

  render() {
    const { failedModalOpen, displaySuccessBody } = this.state;
    const { error } = this.props;
    return (
      <Fragment>
        <Helmet>
          <title>{CREATE_PROFILE_TITLE}</title>
          <meta name="description" content={CREATE_PROFILE_DESC} />
        </Helmet>
        <div className="page_container create-profile-page">
          <div className="box-content">
            <h1>Create Profile</h1>
          </div>
          <div className="box-panel center">
            <div className="auth__container">
              {displaySuccessBody
                ? this.renderSuccessMessage()
                : this.renderFormContent()}
            </div>
          </div>
        </div>
        <AuthErrorModal
          errorBody={error}
          visible={failedModalOpen}
          onClose={() => this.setState({ failedModalOpen: false })}
          onOk={() => this.setState({ failedModalOpen: false })}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authReducer }) => ({
  postStatus: authReducer.createProfileReducer.status,
  successMessage: authReducer.createProfileReducer.successMessage,
  error: authReducer.createProfileReducer.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createProfile }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateProfile);
