import React, { Component, Fragment } from "react";
import SelectCourse from "./SelectCourse";
import { routesContext } from "../../../shared/routes/routes-context";

export default class SelectCourses extends Component {
  static contextType = routesContext;

  render() {
    return (
      <Fragment>
        <div className="container">
          <section className="post-score">
            <SelectCourse
              {...this.props}
              hasManuallyInput={false}
              navItemLink={this.context.selectTee.path}
            />
          </section>
        </div>
      </Fragment>
    );
  }
}
