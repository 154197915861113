import {
  GOLFER_ASSOCIATIONS_FETCH,
  fetchContactAssociationsFailed,
  fetchContactAssociationsSuccess,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$
    .pipe(ofType(GOLFER_ASSOCIATIONS_FETCH))
    .pipe(switchMap(({ payload }) => fetchContactAssociations(payload)));

const fetchContactAssociations = (payload) =>
  from(GolferApi.getGolferAssociations(payload.ghinNumber))
    .pipe(
      map((response) =>
        fetchContactAssociationsSuccess({
          associations: response.data.Associations,
          memberships: payload.memberships,
        })
      )
    )
    .pipe(catchError((error) => of(fetchContactAssociationsFailed(error))));
