import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { getFormattedDate } from "../../../../shared/helpers/date-helper";
import DonutChart from "../../../../shared/components/charts/DonutChart";
import AdvancedStats from "../../../../shared/components/charts/AdvancedStats";
import { getScoreStatsType } from "../../../../shared/helpers/stats-helper";
import ChartType from "../../../../shared/variables/score-chart-type";
import { isEmpty, stringToFloat } from "../../../../shared/helpers/ui-helper";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";
import ScaledScoresInfoModal from "../../../../shared/components/modals/ScaledScoresInfoModal.js";
import {
  getAdjustmentsButton,
  getAdjustmentsInfoMessage,
  getPccFromScoreAdjustments,
  getCourseDisplayValueForCScore,
  getDifferential,
  getDifferentialButton,
  getDifferentialInfoMessage,
} from "../../../../shared/helpers/scores-helper";
import moment from "moment";
import ScoreType from "../../../../shared/variables/stats-score-type";
import CombinedScoreInfoModal from "./CombinedScoreInfoModal";
import { routesContext } from "../../../../shared/routes/routes-context";

class ContentListItem extends Component {
  static contextType = routesContext;
  state = {
    showAdjustmentsModal: false,
    showDifferentialModal: false,
    expandedStats: false,
    hasToolTip: false,
    showCombinedScoresInfoModal: false,
    showScaledScoresInfoModal: false,
  };

  componentDidMount() {
    if (this.courseLabelRef && this.courseCellRef) {
      const margin = 25;
      if (
        this.courseLabelRef.offsetWidth + margin >
        this.courseCellRef.offsetWidth
      ) {
        this.setState({ hasToolTip: true });
      } else {
        this.setState({ hasToolTip: false });
      }
    }
  }

  goToPage = () => {
    const { score } = this.props;
    const statsType = getScoreStatsType(score.statistics);
    console.log(statsType);
    if (statsType === ChartType.ADVANCED_STATS) {
      this.context.roundSummaryHbh.navigate(score, true);
    } else if (statsType === ChartType.STATS) {
      this.context.roundSummaryHbh.navigate(score, false);
    } else {
      this.context.roundSummaryTotal.navigate(score);
    }
  };

  renderStatsIndicator() {
    const { score, deletedScore } = this.props;
    const statsType = getScoreStatsType(score.statistics);
    return deletedScore ? (
      <div className="card__cell stats deleted-score">
        <span>DELETED</span>
      </div>
    ) : (
      <div className="card__cell stats">
        {statsType === ChartType.ADVANCED_STATS ? (
          <button className="btn comp" onClick={this.goToPage}>
            <i className="material-icons-outlined btn-in">trending_up</i>
          </button>
        ) : (
          statsType !== ChartType.STATS && <div>No Stats</div>
        )}
        <button className="btn comp" onClick={this.goToPage}>
          <i className="material-icons-outlined">chevron_right</i>
        </button>
      </div>
    );
  }

  renderDonutChartButton = () => {
    const { score, onViewRoundStats } = this.props;
    return (
      <div className="rar_container">
        <button
          className="btn outline blue"
          onClick={() => onViewRoundStats(score)}
        >
          View Scorecard
        </button>
      </div>
    );
  };

  renderAdvancedStatsButton = () => {
    const { score, onEditRoundStats } = this.props;
    const showEdit =
      getScoreStatsType(score.statistics) === ChartType.ADVANCED_STATS;
    if (showEdit) {
      return (
        <div className="rar_container">
          <button
            className="btn outline blue"
            onClick={() => onEditRoundStats(score)}
          >
            EDIT ROUND STATS
          </button>
        </div>
      );
    }
    return null;
  };

  renderScoreStats() {
    const { expandedStats } = this.state;
    if (!expandedStats) return null;
    const { score, onEditRoundStats, golferActive } = this.props;
    return (
      <div>
        {this.renderAdvancedStatsStatus()}
        <div
          className={golferActive ? "card_more" : "card_more disable_divider"}
        >
          <DonutChart
            statistics={score.statistics}
            renderDonutChartButton={this.renderDonutChartButton}
          />
          <AdvancedStats
            statistics={score.statistics}
            golferActive={golferActive}
            courseId={score.course_id}
            onAddStatsToRound={() => onEditRoundStats(score)}
            renderAdvancedStatsButton={this.renderAdvancedStatsButton}
          />
        </div>
      </div>
    );
  }

  getCourseLabel = (score) => {
    if (score.score_type === "C") {
      return getCourseDisplayValueForCScore(score.course_display_value);
    }
    const course =
      score.facility_name !== score.course_name ? score.course_name : "";
    const line = course && score.facility_name !== null ? "\n" : "";
    return `${score.facility_name || ""}${line}${course}`;
  };

  renderAdvancedStatsStatus = () => {
    const { score } = this.props;
    if (isEmpty(score.statistics.last_stats_update_type)) return null;

    return (
      <p className="score-advanced-stats-status">
        Advanced Stats{" "}
        {score.statistics.last_stats_update_type === "modify"
          ? "Updated"
          : "Deleted"}{" "}
        {moment(score.statistics.last_stats_update_date).format("MM/DD/YYYY")}
      </p>
    );
  };

  closeAdjustmentsModal = () => this.setState({ showAdjustmentsModal: false });

  showAdjustmentsModal = () => this.setState({ showAdjustmentsModal: true });

  closeDifferentialModal = () =>
    this.setState({ showDifferentialModal: false });

  showDifferentialModal = () => this.setState({ showDifferentialModal: true });

  renderCombinedScoresInfo() {
    const { score } = this.props;
    if (score.score_type_display_short === "N") {
      return (
        <button
          onClick={() => this.setState({ showCombinedScoresInfoModal: true })}
          className="info-modal-trigger"
          style={{ marginLeft: 5 }}
          aria-label="Combined Score Info"
        >
          i
        </button>
      );
    }
  }

  renderScaledScoresInfo() {
    const { score } = this.props;
    if (score.number_of_played_holes < 18) {
      return (
        <button
          onClick={() => this.setState({ showScaledScoresInfoModal: true })}
          className="info-modal-trigger"
          style={{ marginLeft: 5 }}
        >
          i
        </button>
      );
    }
  }

  renderPlayedHoles() {
    const { score } = this.props;
    if (score.number_of_played_holes > 9 && score.number_of_played_holes < 18) {
      return (
        <span className="subscript">{`(${score.number_of_played_holes})`}</span>
      );
    }
  }

  render() {
    const {
      score,
      nineHole,
      deletedScore,
      filter,
      style,
      golferActive,
      trialGolfer,
      useScaling,
      hasHandicapIndex,
    } = this.props;
    const {
      hasToolTip,
      showAdjustmentsModal,
      showDifferentialModal,
      showCombinedScoresInfoModal,
      showScaledScoresInfoModal,
    } = this.state;
    const courseLabel = this.getCourseLabel(score);
    const active = this.state.expandedStats ? "active" : "";
    return (
      <Fragment>
        <div className={`card ${active}`} style={style}>
          <div
            className={`card__cell esc-score ${nineHole ? "nineHole" : ""} ${
              deletedScore ? "deletedScore" : ""
            }`}
          >
            <strong
              className={`${deletedScore ? "medium_blue_2" : "dark-blue"}`}
            >
              {filter === ScoreType.RECENT_AND_REVISION_SCORES &&
                score.used &&
                golferActive &&
                !trialGolfer &&
                "* "}
              {score.adjusted_gross_score}
              {useScaling && this.renderPlayedHoles()}
              {score.score_type_display_short}
              {useScaling
                ? hasHandicapIndex && this.renderScaledScoresInfo()
                : this.renderCombinedScoresInfo()}
            </strong>
          </div>
          <div className="card__cell date">
            {getFormattedDate(score.played_at)}
          </div>
          {golferActive && !trialGolfer && (
            <div className="card__cell rating-slope">
              {stringToFloat(score.course_rating, 1)}/{score.slope_rating}
            </div>
          )}
          {golferActive && !trialGolfer && (
            <div className="card__cell pcc">
              {getPccFromScoreAdjustments(score)}
            </div>
          )}
          {golferActive && !trialGolfer && (
            <div className="card__cell differential">
              {getDifferential(score, this.props.useScaling)}
              {getAdjustmentsButton(score, this.showAdjustmentsModal)}
              {getDifferentialButton(score, this.showDifferentialModal)}
            </div>
          )}
          <div
            ref={(ref) => (this.courseCellRef = ref)}
            className="card__cell course"
            data-tip={hasToolTip ? courseLabel : ""}
          >
            <ReactTooltip place="top" type="dark" effect="float" />
            <strong ref={(ref) => (this.courseLabelRef = ref)}>
              <span style={{ whiteSpace: "pre" }}>{courseLabel}</span>
            </strong>
          </div>
          {this.renderStatsIndicator()}
        </div>
        {this.renderScoreStats()}
        <YesNoModal
          isOpen={showAdjustmentsModal}
          onAbort={this.closeAdjustmentsModal}
          onConfirm={this.closeAdjustmentsModal}
          className="modal-dialog"
          appElement={document.getElementById("root")}
          isNoVisible={false}
          yesTitle="Ok"
        >
          <p>{getAdjustmentsInfoMessage(score)}</p>
        </YesNoModal>
        <YesNoModal
          isOpen={showDifferentialModal}
          onAbort={this.closeDifferentialModal}
          onConfirm={this.closeDifferentialModal}
          className="modal-dialog"
          appElement={document.getElementById("root")}
          isNoVisible={false}
          yesTitle="Ok"
        >
          {getDifferentialInfoMessage()}
        </YesNoModal>
        <CombinedScoreInfoModal
          score={score}
          isOpen={showCombinedScoresInfoModal}
          onClose={() => this.setState({ showCombinedScoresInfoModal: false })}
        />
        <ScaledScoresInfoModal
          isOpen={showScaledScoresInfoModal}
          onClose={() => this.setState({ showScaledScoresInfoModal: false })}
        />
      </Fragment>
    );
  }
}

ContentListItem.propTypes = {
  useScaling: PropTypes.bool,
  hasHandicapIndex: PropTypes.bool,
  score: PropTypes.shape({}).isRequired,
  nineHole: PropTypes.bool.isRequired,
  deletedScore: PropTypes.bool.isRequired,
  onEditRoundStats: PropTypes.func.isRequired,
  onViewRoundStats: PropTypes.func.isRequired,
  onDeleteRoundStats: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

ContentListItem.defaultProps = {
  style: {},
};

export default ContentListItem;
