import { getUser, isUserLoggedIn, userToken } from "./user-helper";

export const golferCredentials = () => {
  return {
    golfer_id: isUserLoggedIn() ? getUser().ghin_number : "",
    last_name: isUserLoggedIn() ? getUser().last_name : "",
  };
};

export const golferId = (ghinNumber) => ({
  golfer_id: ghinNumber || (isUserLoggedIn() ? getUser().ghin_number : ""),
});

export const getGolferId = (ghinNumber) =>
  ghinNumber || (isUserLoggedIn() ? getUser().ghin_number : "");

export const getUserToken = () => userToken() || "";

export const golferGender = (gender) => ({
  gender: gender || isUserLoggedIn() ? getUser().gender : "",
});
