import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ScoreType from "../../../../shared/variables/stats-score-type";
import jobStatus from "../../../../shared/variables/job-status";
import Loader from "../../../../shared/components/loaders/Loader";
import LoadMore from "../../../../shared/components/loaders/LoadMore";
import {
  getStatsScores,
  fetchStatsScoresNext,
  updateStatsScores,
  fetchAverageScores,
} from "../../actions";
import HeaderFilterView from "../shared/HeaderFilterView";
import ContentList from "../shared/ContentList";
import ChartsView from "./ChartsView";
import EditRoundStats from "../../../post-score/components/EditRoundStats/EditRoundStats";
import { routesContext } from "../../../../shared/routes/routes-context";
import { getUserGhinNo } from "../../../../shared/helpers/user-helper";
import { Helmet } from "react-helmet";
import {
  STATS_STATS_TITLE,
  STATS_STATS_DESC,
} from "../../../../shared/variables/meta-seo";

class ScoreHistory extends Component {
  static contextType = routesContext;
  state = {
    filter: this.props.golferActive
      ? ScoreType.RECENT_AND_REVISION_SCORES
      : ScoreType.YEAR,
    selectedClub: undefined,
    extraParams: undefined,
    showEditRoundStats: false,
    showViewRoundStats: false,
    editRoundStatsScore: undefined,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { fetchStatus } = nextProps;
    if (
      this.props.fetchStatus !== fetchStatus &&
      fetchStatus === jobStatus.SUCCESS
    ) {
      this.fetchAverageScores();
    }
  }

  componentDidMount() {
    this.fetchScores();

    const scoreFromRoute = this.getScoreSentOnRoute();
    if (scoreFromRoute) {
      const showViewRoundStats = this.getViewRoundStatsOnRoute();
      this.setState({
        showViewRoundStats,
        showEditRoundStats: showViewRoundStats ? false : true,
        editRoundStatsScore: scoreFromRoute,
      });
    }
  }

  fetchAverageScores() {
    const { filter, extraParams } = this.state;
    this.props.fetchAverageScores(
      filter,
      extraParams?.course_id,
      extraParams?.tee_set_id
    );
  }

  fetchScores() {
    const { filter } = this.state;
    this.props.getStatsScores(getUserGhinNo(), filter, true);
  }

  getScoreSentOnRoute = () => {
    try {
      return this.props.history.location.state.score;
    } catch {
      return undefined;
    }
  };

  getViewRoundStatsOnRoute = () => {
    try {
      return this.props.history.location.state.onlyView;
    } catch {
      return false;
    }
  };

  fetchMoreScores = () => {
    const { filter, extraParams } = this.state;
    const { offset } = this.props;
    this.props.fetchStatsScoresNext(filter, offset, true, extraParams);
  };

  handleChangeFilter = (filter, extraParams = undefined) => {
    this.setState({
      filter: filter,
      selectedClub:
        filter !== ScoreType.BY_COURSE ? undefined : this.state.selectedClub,
      extraParams:
        filter !== ScoreType.BY_COURSE_ID_AND_TEE_SET_ID
          ? undefined
          : extraParams,
    });
    this.props.getStatsScores(getUserGhinNo(), filter, true, extraParams);
  };

  handleChangeClub = (selectedClub) => {
    this.setState({ selectedClub });
  };

  getScoresWithStatistics = () => {
    const { scores } = this.props;
    const { selectedClub } = this.state;
    let filteredScores = scores.filter((s) => s.statistics !== null);
    if (selectedClub) {
      const filter = selectedClub.split("_");
      const filterClubAndTee = selectedClub.split("-");
      if (filterClubAndTee[1] && filterClubAndTee[1] !== "null") {
        filteredScores = scores.filter(
          (s) =>
            s.course_id === filterClubAndTee[0] &&
            s.tee_set_id === filterClubAndTee[1]
        );
      } else if (filter[1] && filter[1] !== "null") {
        filteredScores = scores.filter(
          (s) => s.course_name === filter[0] && s.tee_name === filter[1]
        );
      } else {
        filteredScores = scores.filter((s) => s.course_name === filter[0]);
      }
    }
    return filteredScores;
  };

  onEditRoundStats = (score) => {
    if (score.course_id) {
      this.setState({
        showEditRoundStats: true,
        editRoundStatsScore: score,
      });
    } else {
      this.context.statsEditCombinedScore.navigate(score);
    }
  };

  onViewRoundStats = (score) => {
    if (score.course_id) {
      this.setState({
        showViewRoundStats: true,
        editRoundStatsScore: score,
      });
    } else {
      this.context.statsEditCombinedScore.navigate(score, true);
    }
  };

  viewAvgScorePerHole = (selectedClub) => {
    this.context.viewAvgScorePerHole.navigate(getUserGhinNo(), selectedClub);
  };

  renderContent() {
    const scores = this.getScoresWithStatistics();
    const { selectedClub, filter } = this.state;
    const { recentScores, revisionScores, averageScores } = this.props;
    return (
      <Fragment>
        <Helmet>
          <title>{STATS_STATS_TITLE}</title>
          <meta name="description" content={STATS_STATS_DESC} />
        </Helmet>
        <ChartsView averageScores={averageScores} />
        <ContentList
          hideGraph
          filter={filter}
          scores={scores}
          recentScores={recentScores}
          revisionScores={revisionScores}
          selectedClub={selectedClub}
          onEditRoundStats={this.onEditRoundStats}
          onViewRoundStats={this.onViewRoundStats}
          notFoundText="You have no applicable rounds with stats."
        />
      </Fragment>
    );
  }

  render() {
    const { showEditRoundStats, showViewRoundStats } = this.state;
    if (showEditRoundStats) return this.renderEditRoundStats();
    if (showViewRoundStats) return this.renderViewRoundStats();
    return this.renderStats();
  }

  renderStats = () => {
    const { offset, fetchStatus, fetchAverageStatus, fetchNextStatus } =
      this.props;
    const scores = this.getScoresWithStatistics();
    return (
      <Fragment>
        <HeaderFilterView
          scores={scores}
          handleChangeFilter={this.handleChangeFilter}
          handleChangeClub={this.handleChangeClub}
          viewAvgScorePerHole={this.viewAvgScorePerHole}
        />
        {fetchStatus === jobStatus.SUCCESS &&
        fetchAverageStatus === jobStatus.SUCCESS ? (
          this.renderContent()
        ) : (
          <Loader />
        )}
        <LoadMore
          onLoadMore={this.fetchMoreScores}
          offset={offset}
          fetchStatus={fetchNextStatus}
        />
      </Fragment>
    );
  };

  renderEditRoundStats = () => (
    <EditRoundStats
      score={this.state.editRoundStatsScore}
      onConfirm={(score) => {
        this.props.updateStatsScores(score);
        this.setState({ showEditRoundStats: false }, () => this.fetchScores());
      }}
      onCancel={() => this.setState({ showEditRoundStats: false })}
    />
  );

  renderViewRoundStats = () => (
    <EditRoundStats
      onlyView
      score={this.state.editRoundStatsScore}
      onConfirm={(score) => {
        this.props.updateStatsScores(score);
        this.setState({ showViewRoundStats: false }, () => this.fetchScores());
      }}
      onCancel={() => this.setState({ showViewRoundStats: false })}
    />
  );
}

ScoreHistory.propTypes = {
  history: PropTypes.object,
  getStatsScores: PropTypes.func.isRequired,
  fetchStatsScoresNext: PropTypes.func.isRequired,
  updateStatsScores: PropTypes.func.isRequired,
  fetchStatus: PropTypes.string,
  fetchNextStatus: PropTypes.bool.isRequired,
  offset: PropTypes.number.isRequired,
  scores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  recentScores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  revisionScores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  averageScores: PropTypes.shape({}),
  fetchAverageStatus: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ statsReducer, profileReducer }) => ({
  scores: statsReducer.scoresReducer.scores,
  recentScores: statsReducer.scoresReducer.recentScores,
  golferActive: profileReducer.golferProfileFetchReducer.golferActive,
  revisionScores: statsReducer.scoresReducer.revisionScores,
  offset: statsReducer.scoresReducer.offset,
  fetchStatus: statsReducer.scoresReducer.jobStatus,
  fetchNextStatus: statsReducer.scoresReducer.fetchNextStatus,
  averageScores: statsReducer.averageScoresReducer.data,
  fetchAverageStatus: statsReducer.averageScoresReducer.jobStatus,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getStatsScores,
      fetchStatsScoresNext,
      updateStatsScores,
      fetchAverageScores,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ScoreHistory);
