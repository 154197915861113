import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { getRememberMe } from "../../../../shared/helpers/local-storage";
import keyboardKeys from "../../../../shared/variables/keyboard-keys";
import LoaderButton from "../../../../shared/components/buttons/LoaderButton";
import jobStatus from "../../../../shared/variables/job-status";
import { login } from "../../actions";
import { routesContext } from "../../../../shared/routes/routes-context";
import AuthErrorModal from "../../../auth/components/common/AuthErrorModal";

class Login extends Component {
  static contextType = routesContext;
  state = {
    showPassword: false,
    isLoginFailedOpen: false,
  };

  validationSchema = Yup.object({
    emailOrGhin: Yup.string().required("Email Address or GHIN Number Required"),
    password: Yup.string().required("Password Required"),
  });

  navigateToForgotLogin = () => this.context.forgotLogin.navigate();

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { postStatus } = nextProps;
    if (
      postStatus !== this.props.postStatus &&
      postStatus === jobStatus.FAILED
    ) {
      this.setState({ isLoginFailedOpen: true });
    }

    if (postStatus === jobStatus.SUCCESS) {
      this.props.authorizeUser();
    }
  }

  onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    this.props.login(values.password, values.emailOrGhin);
  };

  onToggleShowPassword = () =>
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));

  render() {
    const rememberPayload = getRememberMe();
    const { error } = this.props;
    const { isLoginFailedOpen } = this.state;
    return (
      <section className="page_container">
        <div className="box">
          <div className="box-head">
            <h3 className="box-title">Login</h3>
          </div>
          <Formik
            onSubmit={this.onSubmit}
            initialValues={{
              password: rememberPayload?.password,
              emailOrGhin: rememberPayload?.emailOrGhin,
              remember_me: rememberPayload?.remember_me,
            }}
            validationSchema={this.validationSchema}
          >
            {(options) => this.renderForm(options)}
          </Formik>
          <AuthErrorModal
            visible={isLoginFailedOpen}
            onClose={() => this.setState({ isLoginFailedOpen: false })}
            onOk={() => this.setState({ isLoginFailedOpen: false })}
            errorBody={error}
          />
        </div>
      </section>
    );
  }

  renderForm(options) {
    const { showPassword } = this.state;
    return (
      <div className="box-body">
        <div className="form columns">
          <div className="row">
            <div className="col is-full">
              <label className="" htmlFor="login_id">
                Email Address or GHIN Number
              </label>
              <input
                type="text"
                id="emailOrGhin"
                name="emailOrGhin"
                value={options.values.emailOrGhin}
                onBlur={options.handleBlur}
                onChange={options.handleChange}
              />
              <span className="error">
                {options.touched.emailOrGhin ? options.errors.emailOrGhin : ""}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col is-full">
              <label className="" htmlFor="login_password">
                Password
              </label>
              <div className="show_password_wrapper">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={options.values.password}
                  onBlur={options.handleBlur}
                  onChange={options.handleChange}
                  onKeyDown={(e) => {
                    if (e.keyCode === keyboardKeys.ENTER) {
                      options.handleSubmit();
                    }
                  }}
                />

                <div className="show_password">
                  <button
                    onClick={this.onToggleShowPassword}
                    className="btn lnk no-underline show_button"
                  >
                    {showPassword ? "HIDE" : "SHOW"}
                  </button>
                </div>
              </div>
              <span className="error">
                {options.touched.password ? options.errors.password : ""}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col is-2-of-3 h-centered">
              <LoaderButton
                className="btn fill cardinal"
                onClick={options.handleSubmit}
                loading={String(this.props.postStatus === jobStatus.PROCESSING)}
              >
                LOG IN
              </LoaderButton>
            </div>
          </div>
          <div className="row">
            <div className="col is-full">
              <button onClick={this.navigateToForgotLogin} className="btn lnk">
                Forgot Login Information?
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  forgotLoginAction: PropTypes.func.isRequired,
  postStatus: PropTypes.string.isRequired,
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  postStatus: PropTypes.string.isRequired,
};

const mapStateToProps = ({ loginWithGhinReducer }) => ({
  postStatus: loginWithGhinReducer.loginReducer.postStatus,
  error: loginWithGhinReducer.loginReducer.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ login }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
