import React, { Component, Fragment } from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { routesContext } from "../../../../shared/routes/routes-context";
import PromoBanner from "../../../../shared/components/banners/PromoBanner";

export default class PostHbhScoreContainer extends Component {
  static contextType = routesContext;
  render() {
    return (
      <Fragment>
        <PromoBanner />
        <section className="post-score">
          <ul className="tabs light">
            <li>
              <NavLink
                to={this.context.postScoreTotal.path}
                activeClassName="is-active"
              >
                Total Score
              </NavLink>
            </li>
            <li>
              <NavLink
                to={this.context.postScoreHbh.path}
                activeClassName="is-active"
              >
                Hole-By-Hole Score
              </NavLink>
            </li>
          </ul>
          <Switch>
            <Redirect
              exact
              from={this.context.postScoreTotal.path}
              to={this.context.postScoreTotalSelectCourse.path}
            />
            <Route {...this.context.postScoreTotalSelectCourse} />
            <Route {...this.context.postScoreTotalMyCourses} />
            <Route {...this.context.postScoreTotalPost} />
            <Route {...this.context.postScoreTotalSummary} />
          </Switch>
        </section>
      </Fragment>
    );
  }
}

PostHbhScoreContainer.propTypes = {
  match: PropTypes.object.isRequired,
};
