import { parseAuthError } from "../../../shared/helpers/api-helper-b";
import jobStatus from "../../../shared/variables/job-status";
import userStatus from "../../../shared/variables/user-status";

import {
  setTokenCookie,
  clearTokenCookie,
} from "../../../shared/helpers/store-token-helper";

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  PERSIST_INITIAL_LOGIN,
  LOGOUT,
  WAIT_TO_ACCEPT_TERMS,
  USER_ACCEPTED_TERMS,
  USER_DENIED_TERMS,
  WAIT_TO_SELECT_GOLFER,
  SELECT_GOLFER,
  UPDATE_MINORS,
  START_FETCH,
  STOP_FETCH,
  RESET_FORM,
} from "../actions";

const initialState = {
  error: undefined,
  postStatus: jobStatus.NOT_STARTED,
  userStatus: userStatus.NOT_LOGGED,
  user: undefined,
  golfers: [],
  minors: [],
  userToken: undefined,
  guardianId: undefined,
  golfer_creation_date: undefined,
  golferUserAcceptedTerms: undefined,
  subscription: undefined,
  free_trial: undefined,
  isTrial: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        postStatus: jobStatus.PROCESSING,
        userStatus: userStatus.NOT_LOGGED,
      };
    case LOGIN_SUCCESS: {
      setTokenCookie(payload.golfer_user_token);
      return {
        ...state,
        user: payload.golfers[0],
        users: payload.golfers,
        userToken: payload.golfer_user_token,
        postStatus: jobStatus.SUCCESS,
        guardianId: payload?.guardian_id,
        golfer_creation_date: payload?.golfer_creation_date,
        subscription: payload?.subscription,
        free_trial: payload?.free_trial,
        isTrial: payload?.is_trial,
      };
    }
    case LOGIN_FAILED:
      return {
        ...state,
        postStatus: jobStatus.FAILED,
        userStatus: userStatus.NOT_LOGGED,
        error: parseAuthError(payload?.errors),
      };
    case SELECT_GOLFER:
      return {
        ...state,
        user: payload,
        postStatus: jobStatus.SUCCESS,
        userStatus: userStatus.LOGGED_IN,
      };
    case PERSIST_INITIAL_LOGIN:
      setTokenCookie(payload?.golfer_user_token);
      return {
        ...state,
        postStatus: jobStatus.SUCCESS,
        golfers: payload?.golfers,
        minors: payload?.minor_accounts,
        userToken: payload?.golfer_user_token,
        guardianId: payload?.guardian_id,
        golferUserAcceptedTerms: payload?.golfer_user_accepted_terms,
        golfer_creation_date: payload?.golfer_creation_date,
        subscription: payload?.subscription,
        free_trial: payload?.free_trial,
        isTrial: payload?.is_trial,
      };
    case UPDATE_MINORS:
      return {
        ...state,
        minors: payload,
      };
    case LOGOUT:
      clearTokenCookie();
      return {
        ...state,
        ...initialState,
      };
    case RESET_FORM:
      return {
        ...state,
        error: undefined,
        postStatus: jobStatus.NOT_STARTED,
        userStatus: userStatus.NOT_LOGGED,
      };

    case WAIT_TO_ACCEPT_TERMS:
      return {
        ...state,
        userStatus: userStatus.WAIT_FOR_ACCEPT_TERMS,
        postStatus: jobStatus.SUCCESS,
      };
    case USER_DENIED_TERMS:
      return {
        ...state,
        golferUserAcceptedTerms: false,
        userStatus: userStatus.NOT_LOGGED,
        postStatus: jobStatus.SUCCESS,
      };
    case USER_ACCEPTED_TERMS:
      return {
        ...state,
        golferUserAcceptedTerms: true,
        userStatus: userStatus.NOT_LOGGED,
        postStatus: jobStatus.SUCCESS,
      };
    case WAIT_TO_SELECT_GOLFER:
      return {
        ...state,
        userStatus: userStatus.WAIT_FOR_SELECT_GOLFER,
        postStatus: jobStatus.SUCCESS,
      };
    case START_FETCH:
      return {
        ...state,
        postStatus: jobStatus.PROCESSING,
      };
    case STOP_FETCH:
      return {
        ...state,
        postStatus: jobStatus.NOT_STARTED,
      };
    default:
      return state;
  }
};
