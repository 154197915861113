import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import keyboardKeys from "../../../../shared/variables/keyboard-keys";
import { Formik } from "formik";
import * as Yup from "yup";
import LoaderButton from "../../../../shared/components/buttons/LoaderButton";
import jobStatus from "../../../../shared/variables/job-status";
import { getRememberMe } from "../../../../shared/helpers/local-storage";
import { routesContext } from "../../../../shared/routes/routes-context";

class LoginForm extends Component {
  static contextType = routesContext;
  state = {
    showPassword: false,
  };

  validationSchema = Yup.object({
    emailOrGhin: Yup.string().required("Email Address or GHIN Number Required"),
    password: Yup.string().required("Password Required"),
  });

  onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    this.props.login(values.password, values.emailOrGhin, values.remember_me);
  };

  onToggleShowPassword = () =>
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));

  render() {
    const rememberPayload = getRememberMe();
    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={{
          password: rememberPayload?.password,
          emailOrGhin: rememberPayload?.emailOrGhin,
          remember_me: rememberPayload?.remember_me,
        }}
        validationSchema={this.validationSchema}
      >
        {(options) => this.renderForm(options)}
      </Formik>
    );
  }

  renderForm(options) {
    const link = (
      <a
        href={this.context.createProfile.path}
        style={{
          color: "#0088ce",
          fontWeight: "600",
          fontFamily: "National",
        }}
      >
        Setup a profile now
      </a>
    );
    return (
      <Fragment>
        <div className="login_form columns registration_form columns">
          <div className="login__title sub-title">Log in</div>
          <div className="row">
            <div
              className="col is-full"
              style={{
                height: "90px",
              }}
            >
              <label
                className="black"
                htmlFor="emailOrGhin"
                style={{
                  fontFamily: "National",
                  fontSize: "14px",
                  color: "#000000",
                }}
              >
                EMAIL ADDRESS OR GHIN NUMBER
              </label>
              <input
                type="text"
                id="emailOrGhin"
                value={options.values.emailOrGhin}
                name="emailOrGhin"
                onBlur={options.handleBlur}
                onChange={options.handleChange}
                aria-describedby="emailError"
                aria-invalid={options.errors.emailOrGhin ? "true" : "false"}
                aria-required="true"
                style={{
                  marginBottom: "0px",
                }}
              />
              <span className="error" id="emailError">
                {options.touched.emailOrGhin ? options.errors.emailOrGhin : ""}
              </span>
            </div>
          </div>
          <div
            role={options.errors.emailOrGhin ? "alert" : "none"}
            className="hidden-text"
          >
            {options.touched.emailOrGhin ? options.errors.emailOrGhin : ""}
          </div>
          <div className="row">
            <div
              className="col is-full"
              style={{
                height: "90px",
              }}
            >
              <label
                className="black"
                htmlFor="password"
                style={{
                  fontFamily: "National",
                  fontSize: "14px",
                  color: "#000000",
                }}
              >
                PASSWORD
              </label>
              <div className="show_password_wrapper">
                <input
                  type={this.state.showPassword ? "text" : "password"}
                  id="password"
                  value={options.values.password}
                  name="password"
                  onBlur={options.handleBlur}
                  onChange={options.handleChange}
                  aria-describedby="passError"
                  aria-invalid={options.errors.password ? "true" : "false"}
                  aria-required="true"
                  style={{
                    marginBottom: "0px",
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === keyboardKeys.ENTER) {
                      options.handleSubmit();
                    }
                  }}
                />
                {options.values.password && options.values.password.length > 0 && (
                  <div
                    className="show_password"
                    style={{
                      paddingRight: "5px",
                      display: "flex",
                      alignItems: "center",
                      height: "80%",
                      margin: "5px 5px 5px 5px",
                      paddingLeft: "5px",
                      backgroundColor: "transparent",
                    }}
                  >
                    <button
                      onClick={this.onToggleShowPassword}
                      className="btn lnk no-underline show_button border-focus"
                      style={{
                        height: "10px",
                        alignItems: "center",
                        marginTop: "0px",
                        display: "flex",
                        paddingRight: "10px",
                        paddingTop: "11px",
                      }}
                    >
                      {this.state.showPassword ? "HIDE" : "SHOW"}
                    </button>
                  </div>
                )}
              </div>
              <span className="error" id="passError">
                {options.touched.password ? options.errors.password : ""}
              </span>
            </div>
          </div>
          <div
            role={options.errors.password ? "alert" : "none"}
            className="hidden-text"
          >
            {options.touched.password ? options.errors.password : ""}
          </div>
          <div className="row">
            <div className="col is-full">
              <input
                type="checkbox"
                checked={options.values.remember_me}
                onChange={options.handleChange}
                className="focusable"
                id="remember_me"
                name="remember_me"
              />
              <label className="black label" htmlFor="remember_me">
                Remember Me
              </label>
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "0px",
            }}
          >
            <div className="col is-2-of-3 h-centered">
              <LoaderButton
                className="btn fill cardinal background-focus"
                onClick={options.handleSubmit}
                loading={String(this.props.postStatus === jobStatus.PROCESSING)}
                style={{
                  marginTop: "20px",
                }}
              >
                LOG IN
              </LoaderButton>
            </div>
          </div>
          <br></br>
          <div className="row">
            <div className="login_form center">
              <div className="infoLabe black">Have a GHIN number? {link}</div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  postStatus: PropTypes.string.isRequired,
};

export default LoginForm;
