import React, { Component, Fragment } from "react";
import { routesContext } from "../../../shared/routes/routes-context";
import AddCourses from "./AddCourses";

export default class AddCoursesContainer extends Component {
  static contextType = routesContext;
  render() {
    return (
      <Fragment>
        <div className="container">
          <section className="post-score">
            <AddCourses
              {...this.props}
              hasManuallyInput={false}
              navItemLink={this.context.selectTee.path}
            />
          </section>
        </div>
      </Fragment>
    );
  }
}
