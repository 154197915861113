import { checkApiError } from "../../auth/actions/api-login-methods";

export const FETCH_MAX_HBH_SCORE = "FETCH_MAX_HBH_SCORE";
export const FETCH_MAX_HBH_SCORE_FAILED = "FETCH_MAX_HBH_SCORE_FAILED";
export const FETCH_MAX_HBH_SCORE_SUCCESS = "FETCH_MAX_HBH_SCORE_SUCCESS";
export const FETCH_MAX_HBH_SCORE_RESET = "FETCH_MAX_HBH_SCORE_RESET";

export const fetchMaxHbhScore = (golferId, teeSetId, teeSetSide, date) => ({
  type: FETCH_MAX_HBH_SCORE,
  payload: {
    golferId: golferId,
    teeSetId: teeSetId,
    teeSetSide: teeSetSide,
    playedAt: date,
  },
});

export function fetchMaxHbhScoreFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_MAX_HBH_SCORE_FAILED, payload: { error } });
    dispatch(checkApiError(error));
  };
}

export const fetchMaxHbhScoreSuccess = (payload) => ({
  type: FETCH_MAX_HBH_SCORE_SUCCESS,
  payload,
});

export const fetchMaxHbhScoreReset = () => ({
  type: FETCH_MAX_HBH_SCORE_RESET,
});
