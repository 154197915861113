import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { routesContext } from "../../../../shared/routes/routes-context";

class NavigationLoggedLinks extends Component {
  static contextType = routesContext;
  render() {
    return (
      <ul className="nav__links">
        <li>
          <NavLink
            activeClassName="is-active"
            className="nav__link bottom-border-focus"
            to={this.context.postScore.path}
          >
            POST SCORE
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="is-active"
            className="nav__link bottom-border-focus"
            to={this.context.golferLookup.path}
          >
            GOLFER LOOKUP
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="is-active"
            className="nav__link bottom-border-focus"
            to={this.context.stats.path}
          >
            STATS
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="is-active"
            className="nav__link bottom-border-focus"
            to={this.context.handicapCourseCalculator.path}
          >
            HANDICAP CALCULATOR
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="is-active"
            className="nav__link bottom-border-focus"
            to={this.context.profileHistory.path}
          >
            HANDICAP HISTORY
          </NavLink>
        </li>
      </ul>
    );
  }
}

export default NavigationLoggedLinks;
