import { checkApiError } from "../../auth/actions/api-login-methods";

export const FETCH_STATS_COMBINED_SCORES = "FETCH_STATS_COMBINED_SCORES";
export const FETCH_STATS_COMBINED_SCORES_FAILED =
  "FETCH_STATS_COMBINED_SCORES_FAILED";
export const FETCH_STATS_COMBINED_SCORES_SUCCESS =
  "FETCH_STATS_COMBINED_SCORES_SUCCESS";

export const fetchStatsCombinedScores = (
  ghinNumber,
  onlyStats,
  extraParams
) => ({
  type: FETCH_STATS_COMBINED_SCORES,
  payload: { ghinNumber, onlyStats, extraParams },
});

export function fetchStatsCombinedScoresFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_STATS_COMBINED_SCORES_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchStatsCombinedScoresSuccess = (payload) => ({
  type: FETCH_STATS_COMBINED_SCORES_SUCCESS,
  payload,
});
