import jobStatus from "../../../shared/variables/job-status";
import {
  GOLFER_ASSOCIATIONS_FETCH,
  GOLFER_ASSOCIATIONS_FETCH_FAILED,
  GOLFER_ASSOCIATIONS_FETCH_SUCCESS,
  GOLFER_ASSOCIATIONS_FETCH_RESET,
} from "../actions";

const initialState = {
  golferAssociations: [],
  fetchContactAssociationsStatus: jobStatus.NOT_STARTED,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GOLFER_ASSOCIATIONS_FETCH:
      return {
        ...state,
        fetchContactAssociationsStatus: jobStatus.PROCESSING,
      };
    case GOLFER_ASSOCIATIONS_FETCH_FAILED:
      return {
        ...state,
        fetchContactAssociationsStatus: jobStatus.FAILED,
      };
    case GOLFER_ASSOCIATIONS_FETCH_SUCCESS:
      return {
        ...state,
        golferAssociations: payload.associations.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      };
    case GOLFER_ASSOCIATIONS_FETCH_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
