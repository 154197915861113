import React, { Component, Fragment } from "react";
import MyClubsList from "../golfers/components/MyClubs/MyClubs";
import GolfersListItem from "./GolfersListItem";
import { sortByLastName } from "../../shared/helpers/api-helper-b";
import { Helmet } from "react-helmet";
import {
  GL_MY_CLUBS_TITLE,
  GL_MY_CLUBS_DESC,
} from "../../shared/variables/meta-seo";

class MyClubs extends Component {
  renderItem = (golfer, key) => <GolfersListItem key={key} golfer={golfer} />;

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{GL_MY_CLUBS_TITLE}</title>
          <meta name="description" content={GL_MY_CLUBS_DESC} />
        </Helmet>
        <MyClubsList
          renderItem={this.renderItem}
          sortCriteria={sortByLastName}
        />
      </Fragment>
    );
  }
}

export default MyClubs;
