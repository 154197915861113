import React, { Component } from "react";
import { Formik } from "formik";
import Scores from "./Scores";
import Submit from "../Shared/RoundSetup/Submit";
import numberOfHoles from "../../../../shared/variables/number-of-holes";
import PropTypes from "prop-types";
import {
  initialValues,
  validationSchema,
  validateFields,
} from "../../validations/post-total-score-validation";
import { routesContext } from "../../../../shared/routes/routes-context";
import SharedRoundSetupForm from "../Shared/RoundSetup/SharedRoundSetupForm";
import {
  initialValuesWithHomeCourse,
  hasEighTeenHoles,
} from "../../helpers/round-setup-helper";
import jobStatus from "../../../../shared/variables/job-status";
import { sameAsCurrentDate } from "../../../../shared/helpers/date-helper";

class RoundSetupForm extends Component {
  static contextType = routesContext;
  render() {
    const { isManualCourse, course, manualCourseName: courseName } = this.props;
    const hasEighTeen = hasEighTeenHoles(course, isManualCourse);
    const nrOfHoles = !hasEighTeen
      ? numberOfHoles.NINE
      : initialValues.nrOfHoles;
    const manualCourseName = courseName
      ? courseName
      : initialValues.manualCourseName;
    const values = initialValuesWithHomeCourse(
      { ...initialValues, nrOfHoles, manualCourseName },
      course.CourseId
    );
    return (
      <Formik
        onSubmit={this.onSubmit}
        validate={(values) => validateFields(values, isManualCourse)}
        initialValues={values}
        validationSchema={validationSchema}
      >
        {(options) => this.renderForm(options)}
      </Formik>
    );
  }

  renderForm(options) {
    const { course, isManualCourse, alertNoTees, openInfoModal } = this.props;
    const hasEighTeen = hasEighTeenHoles(course, isManualCourse);
    return (
      <div className="form columns" id="post-score-form">
        <SharedRoundSetupForm
          trialGolfer={this.props.trialGolfer}
          selectCoursePath={this.context.postScoreTotalSelectCourse.path}
          isManualCourse={isManualCourse}
          isManualRatingsEnabled={true}
          course={course}
          options={options}
          hasEighTeen={hasEighTeen}
          hasTenSeventen={false}
          alertNoTees={alertNoTees}
          onResetForm={() => this.resetScores(options)}
          onDatePlayedChange={(date) => this.onDatePlayedChanged(date)}
        />
        <Scores options={options} onOpenInfoModal={openInfoModal} />
        <Submit options={options} title="Post Score" />
      </div>
    );
  }

  onSubmit = (values, { setSubmitting }) => {
    const { postTotalScoreStatus } = this.props;
    if (postTotalScoreStatus !== jobStatus.PROCESSING) {
      this.setValuesBeforeSubmit(values);
      setSubmitting(false);
      this.props.onSubmit(values);
    }
  };

  setValuesBeforeSubmit = (values) => {
    const {
      course,
      course: { Facility },
      isManualCourse,
    } = this.props;
    const courseName =
      Facility && Facility.FacilityName
        ? `${Facility.FacilityName} - ${course.CourseName}`
        : course.CourseName;
    values.isManualCourse = isManualCourse;
    values.courseId = isManualCourse ? "" : course.CourseId;
    values.manualCourseName = isManualCourse
      ? values.manualCourseName
      : courseName;
    values.isManualRating =
      isManualCourse === true ? true : values.isManualRating;
  };

  resetScores = (options) => {
    options.setFieldValue("frontScore", initialValues.frontScore, true);
    options.setFieldValue("backScore", initialValues.backScore, true);
    options.setFieldValue("totalScore", initialValues.totalScore, true);
  };

  onDatePlayedChanged = (date) => {
    if (!sameAsCurrentDate(date)) {
      this.props.openHbhNotSameDayModal();
    }
  };
}

RoundSetupForm.propTypes = {
  course: PropTypes.object.isRequired,
  isManualCourse: PropTypes.bool.isRequired,
  manualCourseName: PropTypes.string,
  alertNoTees: PropTypes.func.isRequired,
  alertInvalidScore: PropTypes.func.isRequired,
  openInfoModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  postTotalScoreStatus: PropTypes.string.isRequired,
};

RoundSetupForm.defaultProps = {
  manualCourseName: undefined,
};

export default RoundSetupForm;
