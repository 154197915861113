import { checkApiError } from "../../auth/actions/api-login-methods";

export const FETCH_COURSE_HANDICAP = "FETCH_COURSE_HANDICAP";
export const FETCH_COURSE_HANDICAP_FAILED = "FETCH_COURSE_HANDICAP_FAILED";
export const FETCH_COURSE_HANDICAP_SUCCESS = "FETCH_COURSE_HANDICAP_SUCCESS";

export const fetchCourseHandicap = (courseId, golferId, playedAt) => ({
  type: FETCH_COURSE_HANDICAP,
  payload: { golferId: golferId, courseId: courseId, playedAt: playedAt },
});

export function fetchCourseHandicapFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_COURSE_HANDICAP_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchCourseHandicapSuccess = (payload) => ({
  type: FETCH_COURSE_HANDICAP_SUCCESS,
  payload,
});
