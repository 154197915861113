import { checkApiError } from "../../auth/actions/api-login-methods";

export const FETCH_TEES = "FETCH_TEES";
export const FETCH_TEES_FAILED = "FETCH_TEES_FAILED";
export const FETCH_TEES_SUCCESS = "FETCH_TEES_SUCCESS";
export const ADD_TEE = "ADD_TEE";
export const ADD_TEE_SUCCESS = "ADD_TEE_SUCCESS";
export const ADD_TEE_FAILED = "ADD_TEE_FAILED";

export const fetchTees = (courseId, callback) => ({
  type: FETCH_TEES,
  payload: {
    courseId: courseId,
    callback: callback,
  },
});

export function fetchTeesFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_TEES_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchTeesSuccess = (payload, callback = null) => {
  if (callback) callback(payload.length);
  return {
    type: FETCH_TEES_SUCCESS,
    payload,
  };
};

export const addTee = (course_id, tee_id, updateCourses) => {
  return {
    type: ADD_TEE,
    payload: {
      course_id,
      tee_id,
      updateCourses,
    },
  };
};

export const addTeeSuccess = (payload, updateCourses) => {
  updateCourses();
  return {
    type: ADD_TEE_SUCCESS,
    payload,
  };
};

export function addTeeFailed(error) {
  return (dispatch) => {
    dispatch({ type: ADD_TEE_FAILED });
    dispatch(checkApiError(error));
  };
}
