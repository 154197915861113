import { FETCH_CLUBS, fetchClubsSuccess, fetchClubsFailed } from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_CLUBS))
    .pipe(switchMap(({ payload }) => getClubs(payload)));

const getClubs = (payload) =>
  from(GolferApi.getClubs(payload))
    .pipe(map((response) => fetchClubsSuccess(response.data.golfer.clubs)))
    .pipe(catchError((error) => of(fetchClubsFailed(error))));
