import React, { Component } from "react";
import DonutChart from "../../../../../shared/components/charts/DonutChart";
import AdvancedStats from "../../../../../shared/components/charts/AdvancedStats";
import { routesContext } from "../../../../../shared/routes/routes-context";

class Scoring extends Component {
  static contextType = routesContext;
  render() {
    const { score } = this.props;
    if (score === null) {
      return null;
    }
    return (
      <div className="stats__container">
        <div className="stats__row">
          {this.renderScoringSummary()}
          {this.renderAdvancedStats()}
        </div>
      </div>
    );
  }

  renderScoringSummary = () => (
    <div className="stats__column">
      <div className="box height100">
        <div className="box-head">
          <h3 className="box-title">Scoring Summary</h3>
        </div>
        <div className="box-body grow center">
          <DonutChart statistics={this.props.score.statistics} />
        </div>
      </div>
    </div>
  );

  renderAdvancedStats = () =>
    this.props.isAdvStatsOn && (
      <div className="stats__column">
        <div className="box height100">
          <div className="box-head box-head--row">
            <h3 className="box-title">Advanced Stats</h3>
            <div className="box-actions">
              <button
                className="btn blank lh24"
                onClick={() =>
                  this.context.statsCharts.navigate(this.props.score)
                }
              >
                Edit Round Stats{" "}
                <i className="material-icons-outlined">chevron_right</i>
              </button>
            </div>
          </div>
          <div className="box-body grow center">
            <AdvancedStats
              showEmptyStats={true}
              statistics={this.props.score.statistics}
            />
          </div>
        </div>
      </div>
    );
}

export default Scoring;
