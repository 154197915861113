const tokenName = "ghin-user-token";

export const setTokenCookie = (token) => {
  localStorage.setItem(tokenName, token);
};

export const getTokenCookie = () => {
  return localStorage.getItem(tokenName);
};

export const clearTokenCookie = () => {
  localStorage.removeItem(tokenName);
};
