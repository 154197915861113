import { store } from "../store/index";
import { genderAcronym, genderName } from "../variables/gender";
import queryString from "query-string";

const getAuthState = () => store.getState().authReducer.loginReducer;

export const getUser = () => getAuthState().user;

export const userToken = () => getAuthState().userToken;

export const isUserLoggedIn = () => getAuthState().user !== undefined;

export const getUserGender = () =>
  getUser()
    ? getUser().gender === genderAcronym.MALE
      ? genderName.MALE
      : genderName.FEMALE
    : undefined;

export const getUserGenderAcronym = () =>
  getUser() ? getUser().gender : undefined;

export const getUserGhinNo = () =>
  getUser() ? getUser().ghin_number : undefined;

export const getUserGuardianId = () =>
  getUser() ? getAuthState().guardianId : undefined;

export const getUserHandicapIndex = () =>
  getUser() ? getUser().low_hi_display : undefined;

export const getUserState = () =>
  getUser() ? getUser().primary_club_state : undefined;

export const getUserCountry = () =>
  getUser() ? getUser().primary_club_country : undefined;

export const golferHasSameClubId = (golfer) => {
  const allUsers = getAuthState().golfers;
  if (golfer && allUsers) {
    const hasSameClub = allUsers.find((g) => g.club_id === `${golfer.club_id}`);
    if (hasSameClub) {
      return true;
    }
  }
  return false;
};

// duplicate in user-helper-b to avoid circular dependencies
export const getGolferId = (golfer) => {
  const id = golfer.ghin_number
    ? golfer.ghin_number
    : golfer.id || golfer.ghin || golfer.manual_golfer_id;
  return parseInt(id, 10);
};

// duplicate in user-helper-b to avoid circular dependencies
export const getGolferGender = (golfer) => golfer.gender || golfer.gender;

export const getHandicap = (golfer) =>
  golfer.handicap_index_display || golfer.handicap_index;

export const mergeMinorGolfer = (golfer) => {
  return {
    ...golfer,
    ghin_number: Number(golfer.ghin || golfer.id),
    player_name:
      golfer.player_name || `${golfer.first_name} ${golfer.last_name}`,
    golf_association_id: golfer.association_id || golfer.golf_association_id,
    association_name: golfer.association_name,
    display: golfer.hi_display,
    primary_club_country: golfer.country,
    primary_club_state: golfer.state,
  };
};

export const canChangeProfile = () => {
  const golfers = getAuthState().golfers;
  const minors = getAuthState().minors;
  if (golfers.length > 0 && minors.length > 0) return true;
  if (golfers.length === 0 && minors.length > 1) return true;
  return false;
};

// duplicate in user-helper-b to avoid circular dependencies
export const filterGhinMemberships = (memberships) => {
  const activeMemberships = memberships?.filter(
    (m) => m.status === "Active" && m.technology_provider === "GHIN"
  );
  if (activeMemberships.length > 0) {
    return activeMemberships.sort((a, b) =>
      a.club_name.localeCompare(b.club_name)
    );
  } else {
    return memberships;
  }
};

// duplicate in user-helper-b to avoid circular dependencies
export const hasActiveMembership = (memberships) => {
  const activeMemberships = memberships?.filter(
    (m) => m.status === "Active" && m.technology_provider === "GHIN"
  );
  if (activeMemberships.length > 0) {
    return true;
  } else {
    return false;
  }
};

// duplicate in user-helper-b to avoid circular dependencies
export const hasTrialMembership = (memberships) => {
  const trialMemberships = memberships?.filter(
    (m) => m.is_trial === true && m.technology_provider === "GHIN"
  );
  if (trialMemberships.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const createOAuthReturnUrl = (location) => {
  const params = queryString.parse(location);
  if (location && params.return_to) {
    let finalUrl = `${process.env.REACT_APP_API_HOST}${params.return_to}?`;
    Object.keys(params).forEach((key) => {
      if (key !== "return_to") {
        finalUrl = `${finalUrl}${key}=${params[key]}&`;
      }
    });
    const redirectParams = queryString.extract(params?.redirect_uri);
    if (redirectParams) {
      finalUrl = `${finalUrl}&${redirectParams}`;
    }
    return finalUrl;
  }
  return undefined;
};

export const concatUserFullName = (
  prefix,
  firstName,
  middleName,
  lastName,
  suffix
) => {
  return [prefix, firstName, middleName, lastName, suffix]
    .filter((x) => x)
    .join(" ");
};
