import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { routesContext } from "../../../../shared/routes/routes-context";
import { canChangeProfile } from "../../../../shared/helpers/user-helper";
import { connect } from "react-redux";
import { getUser } from "../../../../shared/helpers/user-helper";
import { setEvent } from "../../../../firebaseConfig";
import EndOfYearCampaignModal from "../../../end-of-year-campaign/components/EndOfYearCampaignModal";
import { fetchEndOfYearCampaign } from "../../../end-of-year-campaign/actions";
import { bindActionCreators } from "redux";
import InactiveGolferModal from "../../../end-of-year-campaign/components/InactiveGolferModal";
import {
  GHIN_REWIND_YEAR,
  HIDE_GHIN_REWIND_BUTTONS,
} from "../../../end-of-year-campaign/year-end-constants";
import moment from "moment";

class HamburgerMenuLoggedLinks extends Component {
  static contextType = routesContext;
  state = {
    isModalOn: false,
    isButtonVisible: false,
    isInactiveModalOn: false,
  };

  closeModal = () =>
    this.setState({ isModalOn: false, isInactiveModalOn: false });

  openModal = () => {
    if (this.props.golferActive) {
      this.setState({ isModalOn: true });
    } else {
      this.setState({ isInactiveModalOn: true });
    }
    this.props.onClick();
  };
  trapFocus(element) {
    var listItems = element.querySelectorAll("li");
    var displayedListItems = [].filter.call(listItems, function (el) {
      var style = window.getComputedStyle(el);
      return style.display !== "none";
    });

    const focusableLinks = displayedListItems.map((element) => {
      return element.querySelector("a");
    });

    var firstFocusableLink = focusableLinks[0];
    var lastFocusableLink = focusableLinks[focusableLinks.length - 1];
    var KEYCODE_TAB = 9;
    var KEYCODE_ESC = 27;

    element.addEventListener("keydown", function (e) {
      var isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB;
      var isEscPressed = e.key === "Escape" || e.keyCode === KEYCODE_ESC;

      if (!isTabPressed) {
        if (isEscPressed) {
          document.querySelector("button.menu-ham__trigger").focus();
          e.preventDefault();
        }
        return;
      }

      /* shift + tab */
      if (e.shiftKey) {
        if (document.activeElement === firstFocusableLink) {
          lastFocusableLink.focus();
          e.preventDefault();
        }
      } /* tab */ else {
        if (document.activeElement === lastFocusableLink) {
          firstFocusableLink.focus();
          e.preventDefault();
        }
      }
    });
  }

  componentDidMount() {
    this.trapFocus(document.querySelector("#ham__links"));
  }

  // Returns a list of <li> tags representing the Tournaments and Events
  // external URLs.
  getTournamentsURL(associations, memberships) {
    if (!associations) {
      return <></>;
    }

    // List of associations IDs where golfer is actually active.
    let activeAssocs = memberships.map((m) => m.association_id);

    // Process the associations data.
    // returns a list of lists : [ [short_name, URL, category] ]
    let myItems = associations.reduce(function (result, assoc) {
      if (
        assoc.tournaments_and_events_url &&
        assoc.tournaments_and_events_category &&
        activeAssocs.includes(assoc.id)
      ) {
        let short_name = assoc.short_name ? assoc.short_name : "Association";
        result.push([
          short_name,
          assoc.tournaments_and_events_url,
          assoc.tournaments_and_events_category,
        ]);
      }
      return result;
    }, []);

    let listItems = myItems.map((item, index) => {
      return (
        <li key={index} className="order-2">
          <a
            className="menu__link border-focus"
            target="_blank"
            href={item[1]}
            rel="noopener noreferrer"
          >
            <span>{item[0] + " " + item[2]}</span>
          </a>
        </li>
      );
    });

    return <>{listItems}</>;
  }

  getAssocWebsites(associations, memberships) {
    if (!associations) {
      return <></>;
    }
    let activeAssocs = memberships.map((m) => m.association_id);
    // Process the associations data.
    // returns a list of lists : [ [short_name, URL, category] ]
    let myItems = associations.reduce(function (result, assoc) {
      if (assoc.web_address && activeAssocs.includes(assoc.id)) {
        let short_name = assoc.short_name ? assoc.short_name : "Association";
        result.push([short_name, assoc.web_address]);
      }
      return result;
    }, []);

    let listItems = myItems.map((item, index) => {
      return (
        <li key={index} className="order-2">
          <a
            className="menu__link border-focus"
            target="_blank"
            href={"https://" + item[1]}
            rel="noopener noreferrer"
          >
            <span>{item[0] + " Website"}</span>
          </a>
        </li>
      );
    });

    return <>{listItems}</>;
  }

  isGhinRewindButtonVisible() {
    if (HIDE_GHIN_REWIND_BUTTONS) return false;

    const ghin_rewind_year = parseInt(GHIN_REWIND_YEAR);
    const isVisible = this.props.memberships.find((m) => {
      const year_added_to_club = moment(m.created_at).year();
      return (
        m.technology_provider == "GHIN" &&
        year_added_to_club <= ghin_rewind_year
      );
    });

    return isVisible !== undefined && isVisible !== null;
  }

  render() {
    const {
      onClick,
      logout,
      isToggleOn,
      associations,
      memberships,
      golferActive,
      trialGolfer,
    } = this.props;

    const isButtonVisible = this.isGhinRewindButtonVisible();
    const end_of_year_title = GHIN_REWIND_YEAR + " GHIN REWIND";

    this.isGhinRewindButtonVisible();
    return (
      <Fragment>
        <div className="menu__links__wrapper">
          <ul className="menu__links" id="ham__links">
            {golferActive && (
              <li
                className={
                  !trialGolfer ? "hide-on-tablet-and-above" : "order-1"
                }
              >
                <NavLink
                  className="menu__link border-focus"
                  onClick={onClick}
                  activeClassName="is-active"
                  to={this.context.postScore.path}
                >
                  <span>Post Score</span>
                </NavLink>
              </li>
            )}
            {golferActive && !trialGolfer && (
              <li className="hide-on-tablet-and-above">
                <NavLink
                  className="menu__link border-focus"
                  onClick={onClick}
                  activeClassName="is-active"
                  to={this.context.golferLookup.path}
                >
                  <span>Golfer Lookup</span>
                </NavLink>
              </li>
            )}
            <li
              className={
                golferActive && !trialGolfer
                  ? "hide-on-tablet-and-above"
                  : "order-1"
              }
            >
              <NavLink
                className="menu__link border-focus"
                onClick={onClick}
                activeClassName="is-active"
                to={this.context.stats.path}
              >
                <span>Stats</span>
              </NavLink>
            </li>
            {golferActive && (
              <li className="hide-on-tablet-and-above">
                <NavLink
                  className="menu__link border-focus"
                  onClick={onClick}
                  activeClassName="is-active"
                  to={this.context.handicapCalculatorSelectCourse.path}
                >
                  <span>Handicap Calculator</span>
                </NavLink>
              </li>
            )}
            {isButtonVisible && (
              <li className="order-4">
                <button
                  className="menu__link end-of-year-button border-focus"
                  onClick={this.openModal}
                  tabIndex={isToggleOn ? "0" : "-1"}
                >
                  <span className="end-of-year-span">{end_of_year_title}</span>
                </button>
              </li>
            )}
            {golferActive && (
              <li className="hide-on-tablet-and-above">
                <NavLink
                  className="menu__link border-focus"
                  onClick={onClick}
                  activeClassName="is-active"
                  to={this.context.profileHistory.path}
                >
                  <span>Handicap History</span>
                </NavLink>
              </li>
            )}
            {golferActive && !trialGolfer && (
              <li className="order-1">
                <NavLink
                  className="menu__link border-focus"
                  onClick={onClick}
                  activeClassName="is-active"
                  to={this.context.myCard.path}
                  tabIndex={isToggleOn ? "0" : "-1"}
                >
                  <span>My Card</span>
                </NavLink>
              </li>
            )}

            {associations && this.getTournamentsURL(associations, memberships)}

            {associations && this.getAssocWebsites(associations, memberships)}

            <li className="hide-on-tablet-and-above">
              <NavLink
                className="menu__link border-focus"
                onClick={onClick}
                activeClassName="is-active"
                to={this.context.myProfile.path}
              >
                <span>My Profile</span>
              </NavLink>
            </li>
            {golferActive && canChangeProfile() && (
              <li className="order-2">
                <NavLink
                  className="menu__link border-focus"
                  onClick={onClick}
                  activeClassName="is-active"
                  to={this.context.changeGolfer.path}
                  tabIndex={isToggleOn ? "0" : "-1"}
                >
                  <span>CHANGE GOLFER</span>
                </NavLink>
              </li>
            )}
            <li className="order-4">
              <a
                className="menu__link border-focus"
                onClick={onClick}
                target="_blank"
                href="https://www.usga.org/content/usga/home-page/Handicap-ghin/GHIN-Sentry.html"
                rel="noopener noreferrer"
                tabIndex={isToggleOn ? "0" : "-1"}
              >
                <span>About Ghin</span>
              </a>
            </li>
            {trialGolfer && (
              <li className={"order-" + (golferActive ? 3 : 1)}>
                <a
                  className="menu__link border-focus"
                  onClick={() => {
                    onClick();
                    const user = getUser();
                    setEvent(
                      user,
                      "Trial_Sign_Up_Click",
                      "Screen_Name",
                      "More_Menu"
                    );
                  }}
                  target="_blank"
                  href="https://www.usga.org/content/usga/home-page/get-handicap.html?utm_source=ghin&utm_medium=web&utm_campaign=gah"
                  rel="noopener noreferrer"
                >
                  <span>Get A Handicap Index®</span>
                </a>
              </li>
            )}
            <li className="order-5">
              <NavLink
                className="menu__link border-focus"
                onClick={onClick}
                activeClassName="is-active"
                to={this.context.contactUs.path}
                tabIndex={isToggleOn ? "0" : "-1"}
              >
                <span>
                  Contact&nbsp;
                  <span aria-hidden="true">US</span>
                  <span className="visually-hidden">us</span>
                </span>
              </NavLink>
            </li>
            {trialGolfer && (
              <li className="order-5">
                <a
                  className="menu__link border-focus"
                  target="_blank"
                  onClick={onClick}
                  href="https://www.usga.org/content/usga/home-page/Handicap-ghin/ghin-terms-of-service.html"
                  rel="noopener noreferrer"
                >
                  <span>Terms Of Service</span>
                </a>
              </li>
            )}
            {trialGolfer && (
              <li className="order-5">
                <a
                  className="menu__link border-focus"
                  target="_blank"
                  onClick={onClick}
                  href="https://www.usga.org/content/usga/home-page/usga-apps/ghin/trial-terms-conditions.html"
                  rel="noopener noreferrer"
                >
                  <span>GHIN Trial Terms Of Service</span>
                </a>
              </li>
            )}
            <li className="order-6">
              <a
                className="menu__link border-focus"
                onClick={onClick}
                target="_blank"
                href="http://www.usga.org/handicapping/ghin-faqs.html"
                rel="noopener noreferrer"
                tabIndex={isToggleOn ? "0" : "-1"}
              >
                <span>FAQs</span>
              </a>
            </li>
            <li className="order-7">
              <a
                className="menu__link border-focus"
                onClick={onClick}
                target="_blank"
                href={
                  "https://www.usga.org/content/usga/home-page/handicapping/world-handicap-system/2024-revision.html" +
                  "?utm_source=ghin&utm_medium=website&utm_campaign=whs"
                }
                rel="noopener noreferrer"
                tabIndex={isToggleOn ? "0" : "-1"}
              >
                <span>2024 WHS Changes</span>
              </a>
            </li>
          </ul>
          <ul className="menu__links hide-on-tablet-and-above">
            <li>
              <NavLink
                className="menu__link border-focus"
                onClick={() => {
                  onClick();
                  logout();
                }}
                activeClassName="is-active"
                to="/?logout"
              >
                <span>Log Out</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <EndOfYearCampaignModal
          isOpen={this.state.isModalOn}
          onClose={this.closeModal}
        >
          <iframe
            title="End of year campaign"
            width="100%"
            height="100%"
            src={this.context.endOfYearCampaign.path}
          />
        </EndOfYearCampaignModal>
        <InactiveGolferModal
          isOpen={this.state.isInactiveModalOn}
          onClose={this.closeModal}
        ></InactiveGolferModal>
      </Fragment>
    );
  }
}

HamburgerMenuLoggedLinks.propTypes = {
  onClick: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  fetchEndOfYearCampaign: PropTypes.func.isRequired,
};

const mapStateToProps = ({ profileReducer, endOfYearCampaignReducer }) => {
  return {
    associations:
      profileReducer.golferAssociationsFetchReducer.golferAssociations,
    memberships: profileReducer.golferProfileFetchReducer.golferMemberships,
    golferActive: profileReducer.golferProfileFetchReducer.golferActive,
    trialGolfer: profileReducer.golferProfileFetchReducer.trialGolfer,
    stats: endOfYearCampaignReducer.endOfYearCampaignReducer.year_end_summary,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchEndOfYearCampaign,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HamburgerMenuLoggedLinks);
