import { checkApiError } from "../../auth/actions/api-login-methods";

export const POST_COMBINED_SCORES = "POST_COMBINED_SCORES";
export const POST_COMBINED_SCORES_FAILED = "POST_COMBINED_SCORES_FAILED";
export const POST_COMBINED_SCORES_SUCCESS = "POST_COMBINED_SCORES_SUCCESS";
export const POST_COMBINED_SCORES_RESET = "POST_COMBINED_SCORES_RESET";

export const postCombinedScores = (score, roundStats, scoresLength) => ({
  type: POST_COMBINED_SCORES,
  payload: { score, roundStats, scoresLength },
});

export const postCombinedScoresSuccess = (payload, scoresLength) => ({
  type: POST_COMBINED_SCORES_SUCCESS,
  payload: { payload, scoresLength },
});

export function postCombinedScoresFailed(error) {
  return (dispatch) => {
    dispatch({ type: POST_COMBINED_SCORES_FAILED });
    dispatch(checkApiError(error));
  };
}

export const postCombinedScoresReset = () => ({
  type: POST_COMBINED_SCORES_RESET,
});
