import {
  FETCH_GOLFER_PROFILE_HANDICAP,
  fetchGolferProfileHandicapSuccess,
  fetchGolferProfileHandicapFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import GolferApi from "../../../services/golfer-api";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_GOLFER_PROFILE_HANDICAP))
    .pipe(switchMap(({ payload }) => getGolferHandicap(payload)));

const getGolferHandicap = (payload) =>
  from(GolferApi.getHandicapHistory(payload.ghin_number))
    .pipe(
      map((response) =>
        fetchGolferProfileHandicapSuccess(response.data.handicap_revisions)
      )
    )
    .pipe(catchError(() => of(fetchGolferProfileHandicapFailed(payload))));
