import { GHIN_REWIND_YEAR } from "../../screens/end-of-year-campaign/year-end-constants";

export default {
  contactUs: {
    path: "/contact-us",
  },
  ghinAssociations: {
    path: "/ghin-associations",
  },
  aboutUs: {
    path: "/about-us",
  },
  endOfYearCampaign: {
    path: "/end-of-year-campaign",
  },
  shareEndOfYearImage: {
    path: "/" + GHIN_REWIND_YEAR + "-rewind",
  },
};
