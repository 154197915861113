import React, { Component, Fragment } from "react";
import Information from "./Information";
import Links from "./Links";
import Copyright from "./Copyright";
import Accessibility from "./Accessibility";
import { Helmet } from "react-helmet";

class Footer extends Component {
  render() {
    return (
      <Fragment>
        <footer>
          <div className="footer__container">
            <Information />
            <Links />
            <Accessibility />
            <Copyright />
          </div>
          <Helmet>
            <script
              src="https://js.adsrvr.org/up_loader.1.1.0.js"
              type="text/javascript"
            ></script>
            <script type="text/javascript">
              {`
              if (window.ttd_dom_ready) {
                ttd_dom_ready( function() {
                  if (typeof TTDUniversalPixelApi === 'function') {
                    var universalPixelApi = new TTDUniversalPixelApi();
                    universalPixelApi.init("isqkevq", ["hrf2ft2"], "https://insight.adsrvr.org/track/up");
                  }
                })
              };
              `}
            </script>
          </Helmet>
        </footer>
      </Fragment>
    );
  }
}

export default Footer;
