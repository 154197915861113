import { checkApiError } from "../../auth/actions/api-login-methods";

export const FETCH_COURSE_DETAILS = "FETCH_COURSE_DETAILS";
export const FETCH_COURSE_DETAILS_FAILED = "FETCH_COURSE_DETAILS_FAILED";
export const FETCH_COURSE_DETAILS_SUCCESS = "FETCH_COURSE_DETAILS_SUCCESS";
export const FETCH_COURSE_DETAILS_RESET = "FETCH_COURSE_DETAILS_RESET";

export const fetchCourseDetails = (courseId, alteredTees) => ({
  type: FETCH_COURSE_DETAILS,
  payload: { courseId: courseId, includeAlteredTees: alteredTees },
});

export function fetchCourseDetailsFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_COURSE_DETAILS_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchCourseDetailsSuccess = (payload) => ({
  type: FETCH_COURSE_DETAILS_SUCCESS,
  payload,
});

export const fetchCourseDetailsReset = () => ({
  type: FETCH_COURSE_DETAILS_RESET,
});
