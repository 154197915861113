import axios from "axios";
import appConfig from "../shared/config/index";
import { getTokenCookie } from "../shared/helpers/store-token-helper";
import { updateUrl } from "../shared/helpers/api-helper-b";

export const SOURCE = "GHINcom";
class BaseApi {
  baseUrl = appConfig.api.host;

  getBearerToken = (token = undefined) => {
    const userToken = token || getTokenCookie();
    if (userToken) {
      return `Bearer ${userToken}`;
    }
    return undefined;
  };

  getAuthHeader = (token = undefined) => ({
    headers: { Authorization: this.getBearerToken(token) },
  });

  getAuthHeaderFormData = (token = undefined) => ({
    headers: {
      Authorization: this.getBearerToken(token),
      "Content-Type": "multipart/form-data",
    },
  });

  appendSourceToUrl(url) {
    const excludeUrls = ["product_alerts.json", "oauth/authorize"];
    if (excludeUrls.some((v) => url.includes(v))) {
      return url;
    }
    return updateUrl(url, "source", SOURCE);
  }

  appendSourceToData = (data) => ({ ...data, source: SOURCE });

  httpGet(url, token = undefined) {
    return axios.get(this.appendSourceToUrl(url), this.getAuthHeader(token));
  }

  httpPost(url, data = null, token = undefined) {
    return axios.post(
      url,
      this.appendSourceToData(data),
      this.getAuthHeader(token)
    );
  }

  httpPatch(url, data = null) {
    return axios.patch(
      url,
      this.appendSourceToData(data),
      this.getAuthHeader()
    );
  }

  httpPatchFormData(url, data = null) {
    return axios.patch(
      url,
      this.appendSourceToData(data),
      this.getAuthHeaderFormData()
    );
  }

  httpDelete(url, data = null, token = undefined) {
    const accessToken = this.getBearerToken(token);
    return axios.delete(url, {
      data: this.appendSourceToData(data),
      headers: { Authorization: accessToken },
    });
  }
}

export default BaseApi;
