import { checkApiError } from "../../auth/actions/api-login-methods";

export * from "./product-alert-fetch-actions";
export const FETCH_PRODUCT_ALERT_FAILED = "FETCH_PRODUCT_ALERT_FAILED";

export function fetchProductAlertFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_PRODUCT_ALERT_FAILED });
    dispatch(checkApiError(error));
  };
}
