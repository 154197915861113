import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";
import { routesContext } from "../../../../shared/routes/routes-context";
import UserStatus from "../../../../shared/variables/user-status";
import { login } from "../../actions";
import { connect } from "react-redux";
import { isUserLoggedIn } from "../../../../shared/helpers/user-helper";
import { bindActionCreators } from "redux";

class TrialThanks extends Component {
  static contextType = routesContext;
  state = {
    warningOpen: false,
  };

  componentDidMount() {
    if (isUserLoggedIn()) {
      this.context.profile.navigate();
    }
  }

  handleClick = () => {
    this.props.login(
      this.props.formData.password,
      this.props.formData.email,
      false
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { userStatus } = nextProps;
    if (
      userStatus === UserStatus.LOGGED_IN ||
      userStatus === UserStatus.WAIT_FOR_ACCEPT_TERMS
    ) {
      this.context.profile.navigate();
    }
  }

  render() {
    return (
      <Fragment>
        <section
          className="login"
          style={{
            backgroundColor: "white",
          }}
        >
          <div className="thanks__container">
            <div
              className="login__title"
              style={{
                paddingTop: "50px",
                marginBottom: "50px",
              }}
            >
              Thanks for registering!
              <div className="login__title sub-title">
                Get your Handicap Index and unlock all GHIN features
              </div>
            </div>
            <div className="steps-container">
              <div className="step-container">
                <div className="step-number">1.</div>
                <div className="step-details">
                  <p className="step-text">Post your scores</p>
                  <p className="step-subtext">
                    After posting 54 holes of play, you&apos;ll get your
                    Estimated Handicap Range
                  </p>
                </div>
              </div>
              <hr
                style={{
                  width: "75%",
                  textAlign: "center",
                  margintLeft: "auto",
                }}
              ></hr>
              <div className="step-container">
                <div className="step-number">2.</div>
                <div className="step-details">
                  <p className="step-text">Start your 30 day trial</p>
                  <p className="step-subtext">
                    Once your Estimated Handicap Range is established, the trial
                    will last 30 days.
                  </p>
                </div>
              </div>
              <hr
                style={{
                  width: "75%",
                  textAlign: "center",
                  marginLeft: "auto",
                }}
              ></hr>
              <div className="step-container">
                <div className="step-number">3.</div>
                <div className="step-details">
                  <p className="step-text">Sign up for full access</p>
                  <p className="step-subtext">
                    Get a Handicap Index® by joining your local Allied Golf
                    Association (AGA).
                    <br />
                    You’ll also get access to GHIN features including advanced
                    GPS and golfer lookup.
                  </p>
                </div>
              </div>
            </div>
            <div className="thanks__section">
              <button
                className="btn fill cardinal background-focus thanks-button"
                onClick={this.handleClick}
              >
                Start trial now
              </button>
            </div>
          </div>
        </section>
        <YesNoModal
          onConfirm={this.context.profile.navigate}
          onAbort={this.context.profile.navigate}
          isOpen={this.state.warningOpen}
          isYesVisible={true}
          isNoVisible={false}
          yesTitle="Ok"
        >
          <h3>Inactive GHIN Membership</h3>
          <p>
            Your GHIN membership is inactive so you are no longer able to access
            some of the features within GHIN.com. Please contact your Club or
            Golf Association for assistance.
          </p>
        </YesNoModal>
      </Fragment>
    );
  }
}

TrialThanks.propTypes = {
  login: PropTypes.func.isRequired,
  userStatus: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ login }, dispatch);

const mapStateToProps = ({ authReducer }) => ({
  userStatus: authReducer.loginReducer.userStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(TrialThanks);
