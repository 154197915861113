import {
  fetchGolferProfileHandicap,
  fetchGolferProfileHandicapFailed,
} from "../actions/golfer-profile-handicap-fetch";

export const FETCH_GOLFER_PROFILE_HANDICAP_LOWHI =
  "FETCH_GOLFER_PROFILE_HANDICAP_LOWHI";
export const FETCH_GOLFER_PROFILE_HANDICAP_LOWHI_FAILED =
  "FETCH_GOLFER_PROFILE_HANDICAP_LOWHI_FAILED";
export const FETCH_GOLFER_PROFILE_HANDICAP_LOWHI_SUCCESS =
  "FETCH_GOLFER_PROFILE_HANDICAP_LOWHI_SUCCESS";

export function fetchGolferProfileHandicapLowhi(payload) {
  return (dispatch) => {
    dispatch(fetchGolferProfileHandicapFailed());
    dispatch({ type: FETCH_GOLFER_PROFILE_HANDICAP_LOWHI, payload });
  };
}

export function fetchGolferProfileHandicapLowhiFailed(payload) {
  return (dispatch) => {
    dispatch({ type: FETCH_GOLFER_PROFILE_HANDICAP_LOWHI_FAILED });
    dispatch(fetchGolferProfileHandicap(payload));
  };
}

export function fetchGolferProfileHandicapLowhiSuccess(payload) {
  return (dispatch) => {
    dispatch({ type: FETCH_GOLFER_PROFILE_HANDICAP_LOWHI_SUCCESS, payload });
    dispatch(
      fetchGolferProfileHandicap({ ...payload, ghin_number: payload.id })
    );
  };
}
