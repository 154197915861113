import update from "react-addons-update";
import { getGolferId } from "./user-helper-b";
import teeSetSide from "../variables/tee-set-side";
import noOfHoles from "../variables/number-of-holes";
import { removeEmptyTeesHandicapCalculator } from "./transformer-helper";
import { isEmpty } from "./ui-helper";

export const getCourseName = (course) => {
  return course.Facility &&
    course.Facility.FacilityName &&
    course.Facility.FacilityName !== course.CourseName
    ? `${course.Facility.FacilityName} - ${course.CourseName}`
    : course.CourseName;
};

export const userExistInGolfers = (id, golfers) => {
  const golferExist = golfers.filter(
    (g) => getGolferId(g) === parseInt(id, 10)
  );
  if (golferExist.length > 0) {
    return true;
  }
  return false;
};

export const addValuesToGolfer = (golfer, golfers, fields, values) => {
  const i = golfers.findIndex((g) => getGolferId(g) === parseInt(golfer, 10));
  let newData = {};
  fields.forEach((field, index) => (newData[field] = values[index]));
  const updatedGolfer = {
    ...golfers[i],
    ...newData,
  };
  const newGolfers = [...golfers];
  newGolfers[i] = updatedGolfer;
  return newGolfers;
};

export const initCalculator = (currentCourseId, courseId, golfer, golfers) => {
  if (golfer) {
    golfers = [...golfers, golfer];
  }
  if (currentCourseId && currentCourseId !== courseId) {
    return golfers.map((golfer) => {
      golfer.tee_sets = null;
      golfer.selected_tee_set = null;
      return golfer;
    });
  }
  return golfers;
};

export const addTeesToGolfer = (
  tees,
  golfer,
  golfers,
  gender,
  userSelectedTee
) => {
  const teesWithSlopeRating = removeEmptyTeesHandicapCalculator(tees);
  const genderTees = teesWithSlopeRating.tee_sets?.filter(
    (g) => g.gender === gender
  );
  const index = golfers.findIndex(
    (g) => getGolferId(g) === parseInt(golfer, 10)
  );
  const golferSelectedTeeSet = golfers[index].selected_tee_set;
  const items = update(golfers, {
    [index]: {
      tee_sets: { $set: genderTees },
      selected_tee_set: { $set: golferSelectedTeeSet || userSelectedTee },
      fetch_tees: { $set: false },
    },
  });
  return items;
};

export const addSelectedTeeToGolfer = (
  golfer,
  teeId,
  golfers,
  userSelectedTee
) => {
  const index = golfers.findIndex(
    (g) => getGolferId(g) === parseInt(golfer, 10)
  );
  const items = update(golfers, {
    [index]: { selected_tee_set: { $set: teeId } },
  });
  return items.map((item) => {
    item.selected_tee_set = item.selected_tee_set
      ? item.selected_tee_set
      : userSelectedTee;
    return item;
  });
};

export const changeSelectedTeeByHole = (selected_tee_set, numberOfHoles) => {
  if (selected_tee_set) {
    return `${parseInt(selected_tee_set, 10)}_${
      numberOfHoles === noOfHoles.EIGHTEEN ? teeSetSide.All18 : teeSetSide.Front
    }`;
  }
  return selected_tee_set;
};

export const changeSelectedTeeGolfersByHole = (golfers, numberOfHoles) => {
  return golfers.map((golfer) => {
    golfer.selected_tee_set = changeSelectedTeeByHole(
      golfer.selected_tee_set,
      numberOfHoles
    );
    return golfer;
  });
};

export const validateManuallyHCFields = (values) => {
  let errors = {};

  const handicapIndex = values.handicapIndex.replace("+", "-"); //force + to -
  if (isEmpty(handicapIndex)) {
    errors.handicapIndex = "Handicap Index is required";
  }

  if (
    !isEmpty(handicapIndex) &&
    (handicapIndex < -9.9 || handicapIndex > 54.0)
  ) {
    errors.handicapIndex = "value between +9.9 and 54.0";
  }

  if (isEmpty(values.courseRating)) {
    errors.courseRating = "Course Rating is required";
  }

  if (
    !isEmpty(values.courseRating) &&
    (values.courseRating < 20.0 || values.courseRating > 90.0)
  ) {
    errors.courseRating = "value between 20.0 and 90.0";
  }

  if (isEmpty(values.slopeRating)) {
    errors.slopeRating = "Slope Rating is required";
  }

  if (
    !isEmpty(values.slopeRating) &&
    (values.slopeRating < 55 || values.slopeRating > 155)
  ) {
    errors.slopeRating = "value between 55 and 155";
  }

  if (isEmpty(values.par)) {
    errors.par = "Par is required";
  }

  if (!isEmpty(values.par) && (values.par < 20 || values.par > 90)) {
    errors.par = "value between 20 and 90";
  }

  return errors;
};

export const checkGolfersTeeSetsFetch = (golfers) => {
  const withTeeSets = golfers.filter((g) => g.fetch_tees === false);
  return withTeeSets.length === golfers.length;
};

export const getGolfersWithPlayingHandicaps = (golfers) => {
  const results = [];
  const sortedGolfers = sortGolfersByLastName(golfers);
  sortedGolfers.forEach((golfer) => {
    if (golfer.selected_tee_set) {
      const selectedTeeSet = golfer.selected_tee_set.toString().split("_");
      const teeSet = golfer.tee_sets?.find(
        (t) => t.tee_set_id === Number(selectedTeeSet[0])
      );
      if (teeSet) {
        let item = {
          tee_set_id: teeSet.tee_set_id,
          tee_set_side: selectedTeeSet[1],
        };
        if (golfer.manual_golfer_id) {
          item = {
            ...item,
            handicapIndex: Number(golfer.handicap_index),
          };
        } else {
          item = {
            ...item,
            golfer_id:
              Number(golfer.id) ||
              Number(golfer.ghin) ||
              Number(golfer.ghin_number),
          };
        }
        results.push(item);
      }
    }
  });
  return results;
};

export const getPlayingHandicap = (
  values,
  allowance,
  golfer,
  manualGolfersReference,
  type = "playing_handicap_display"
) => {
  if (values && allowance) {
    const allowanceValue = values[allowance];
    if (allowanceValue) {
      const manualGolfer = manualGolfersReference.find(
        (mg) => mg.manual_golfer_id === golfer.manual_golfer_id
      );
      const manualGolferId = manualGolfer
        ? `manual_golfer_${manualGolfer.index}`
        : undefined;
      const golferAllowance =
        allowanceValue[
          golfer.id || golfer.ghin || golfer.ghin_number || manualGolferId
        ];
      if (golferAllowance) {
        return golferAllowance[type];
      }
    }
  }
  return "-";
};

export const getHandicapAllowanceOptions = () => {
  return Array.from({ length: 20 }, (_, i) => ({
    value: (100 - i * 5).toString(),
    label: `${100 - i * 5}%`,
  }));
};

export const validateManuallyGolferHCFields = (values) => {
  let errors = {};

  const handicapIndex = values.handicapIndex.replace("+", "-"); //force + to -
  if (isEmpty(handicapIndex)) {
    errors.handicapIndex = "Handicap Index is required";
  }

  if (
    !isEmpty(handicapIndex) &&
    (handicapIndex < -9.9 || handicapIndex > 54.0)
  ) {
    errors.handicapIndex = "value between +9.9 and 54.0";
  }

  if (isEmpty(values.name)) {
    errors.name = "Name is required";
  }

  if (isEmpty(values.gender)) {
    errors.gender = "Gender is required";
  }

  return errors;
};

export const sortGolfersByLastName = (golfers) => {
  if (golfers.length < 2) return golfers;

  const newGolfers = [...golfers];
  const localUser = { ...newGolfers[0] };
  newGolfers.shift();
  const sortedGolfers = newGolfers.sort((a, b) =>
    a.last_name.localeCompare(b.last_name)
  );
  return [localUser, ...sortedGolfers];
};

const hasTeeSet = (golfer) => {
  const selectedTeeSet = golfer.selected_tee_set.toString().split("_");
  const teeSet = golfer.tee_sets?.find(
    (t) => t.tee_set_id === Number(selectedTeeSet[0])
  );
  if (teeSet) return true;
  return false;
};

export const addIncreaseIdToManualGolfers = (golfers) => {
  const sortedGolfers = sortGolfersByLastName(golfers);
  const manualGolfersReference = sortedGolfers.filter(
    (g) => g.manual_golfer_id && g.selected_tee_set && hasTeeSet(g)
  );

  const returnedGolfers = [];
  manualGolfersReference.forEach((golfer, index) => {
    returnedGolfers.push({
      manual_golfer_id: golfer.manual_golfer_id,
      index: index + 1,
    });
  });
  return returnedGolfers;
};
