export default {
  login: {
    path: "/login",
  },
  trial: {
    path: "/trial",
  },
  createProfile: {
    path: "/create-profile",
  },
  createProfileSetPassword: {
    path: "/auth/create_profile/:token",
  },
  confirmEmailChange: {
    path: "/auth/confirm_email_change/:token",
  },
  forgotLogin: {
    path: "/forgot-login-information",
  },
  setNewPassword: {
    path: "/auth/set/:token",
  },
  selectGolfer: {
    path: "/select-golfer",
  },
  changeGolfer: {
    path: "/change-golfer",
  },
  oauthAuthorize: {
    path: "/new-login",
  },
};
