import React, { Component } from "react";
import PropTypes from "prop-types";
import { getDisplayScore } from "../../../shared/helpers/ui-helper";
import { getCapInfo } from "../../../shared/helpers/ui-helper";
import { routesContext } from "../../../shared/routes/routes-context";
import { getFormattedDate } from "../../../shared/helpers/date-helper";

class ProfileDetails extends Component {
  static contextType = routesContext;
  render() {
    const { profile, golferActive } = this.props;
    var is_trial;
    if (profile) is_trial = profile.is_trial;
    else is_trial = false;

    return (
      <div className={"profile__details" + (is_trial ? " trial" : "")}>
        <h2 className="profile__name">{profile?.player_name}</h2>
        <div className={"handicap__index single" + (is_trial ? " trial" : "")}>
          <div className="data">
            <div className="handicap__value">
              <div className="cap-container">
                <div className="cap">{getCapInfo(profile)}</div>
                {getDisplayScore(profile?.hi_display)}
              </div>
            </div>
          </div>
          {!is_trial && (
            <div className="reg">
              <div className="handicap__reg">
                HANDICAP <br /> INDEX <sup>&reg;</sup>
              </div>
            </div>
          )}
          {is_trial && profile?.hi_display != "NH" && (
            <div className="reg">
              <div className="handicap__reg">
                ESTIMATED <br /> HANDICAP RANGE
              </div>
            </div>
          )}
        </div>

        {!is_trial && (
          <div
            className={"handicap__index single" + (is_trial ? " trial" : "")}
          >
            <div className="data">
              <div className="handicap__trend">
                {profile?.hi_display && profile?.low_hi_display !== "-2"
                  ? `Low H.I.: ${profile.low_hi_display} ${
                      profile?.low_hi_date
                        ? `(${getFormattedDate(profile?.low_hi_date)})`
                        : "-"
                    }`
                  : `Low H.I.: -`}
              </div>
            </div>
          </div>
        )}

        {is_trial && <br></br>}
        <h2 className="profile__ghin">{`GHIN ${
          is_trial ? "Trial " : ""
        }Number: ${profile?.ghin}`}</h2>
        {/* <button id="ot-sdk-btn" className="ot-sdk-show-settings">
          Cookie Settings
        </button> */}
        {/* <div className="handicap__index double">
                                <div className="reg">
                                    <div className="handicap__reg">HANDICAP INDEX <sup>&reg;</sup></div>
                                </div>
                                <div className="data">
                                    <div className="col">
                                        <div className="handicap__value">27.3</div>
                                        <div className="group">
                                            <div className="handicap__hole">18 Hole</div>
                                            <div className="handicap__trend">Trend: 27.2L</div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="handicap__value">4.5N</div>
                                        <div className="group">
                                            <div className="handicap__hole">9 Hole</div>
                                            <div className="handicap__trend">Trend: 4.2NL</div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div> */}

        <div className={"profile__action" + (is_trial ? " trial" : "")}>
          {golferActive && (
            <button
              className="btn fill cardinal"
              onClick={() => this.context.postScore.navigate()}
            >
              Post Score
            </button>
          )}
        </div>
      </div>
    );
  }
}

ProfileDetails.propTypes = {
  profile: PropTypes.object,
};

export default ProfileDetails;
