import { checkApiError } from "../../auth/actions/api-login-methods";

export const FETCH_CHILD_SCORES = "FETCH_CHILD_SCORES";
export const FETCH_CHILD_SCORES_SUCCESS = "FETCH_CHILD_SCORES_SUCCESS";
export const FETCH_CHILD_SCORES_FAILED = "FETCH_CHILD_SCORES_FAILED";

export const FETCH_MAXIMUM_HOLE_SCORES = "FETCH_MAXIMUM_HOLE_SCORES";
export const FETCH_MAXIMUM_HOLE_SCORES_SUCCESS =
  "FETCH_MAXIMUM_HOLE_SCORES_SUCCESS";
export const FETCH_MAXIMUM_HOLE_SCORES_FAILED =
  "FETCH_MAXIMUM_HOLE_SCORES_FAILED";

export const FETCH_SCORE_COURSE_DETAILS = "FETCH_SCORE_COURSE_DETAILS";
export const FETCH_SCORE_COURSE_DETAILS_SUCCESS =
  "FETCH_SCORE_COURSE_DETAILS_SUCCESS";
export const FETCH_SCORE_COURSE_DETAILS_FAILED =
  "FETCH_SCORE_COURSE_DETAILS_FAILED";

export const CHECK_ALL_FETCH_DONE = "CHECK_ALL_FETCH_DONE";
export const FETCH_CHILD_SCORES_DONE = "FETCH_CHILD_SCORES_DONE";
export const FETCH_CHILD_SCORES_RESET = "FETCH_CHILD_SCORES_RESET";

export const fetchChildScores = (
  scoreId,
  onlyScores = false,
  golferId = null
) => ({
  type: FETCH_CHILD_SCORES,
  payload: { scoreId, onlyScores, golferId },
});

export function fetchChildScoresSuccess(payload, onlyScores) {
  return (dispatch) => {
    dispatch({ type: FETCH_CHILD_SCORES_SUCCESS, payload });
    if (onlyScores) {
      dispatch({ type: FETCH_CHILD_SCORES_DONE });
    } else {
      payload.forEach((score) => {
        dispatch(
          fetchMaximumHoleScores(score.id, score.tee_set_id, score.tee_set_side)
        );
        dispatch(fetchScoreCourseDetails(score.course_id, score.id));
      });
      dispatch(checkAllFetchDone());
    }
  };
}

export function fetchChildScoresFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_CHILD_SCORES_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchMaximumHoleScores = (scoreId, teeSetId, teeSetSide) => ({
  type: FETCH_MAXIMUM_HOLE_SCORES,
  payload: { teeSetId, teeSetSide, scoreId },
});

export const fetchMaximumHoleScoresSucces = (maximumScores, scoreId) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_MAXIMUM_HOLE_SCORES_SUCCESS,
      payload: { maximumScores, scoreId },
    });
    dispatch(checkAllFetchDone());
  };
};

export function fetchMaximumHoleScoresFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_MAXIMUM_HOLE_SCORES_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchScoreCourseDetails = (courseId, scoreId) => ({
  type: FETCH_SCORE_COURSE_DETAILS,
  payload: { courseId, scoreId },
});

export const fetchScoreCourseDetailsSucces = (courseDetails, scoreId) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_SCORE_COURSE_DETAILS_SUCCESS,
      payload: { courseDetails, scoreId },
    });
    dispatch(checkAllFetchDone());
  };
};

export function fetchScoreCourseDetailsFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_SCORE_COURSE_DETAILS_FAILED });
    dispatch(checkApiError(error));
  };
}

export const checkAllFetchDone = () => ({
  type: CHECK_ALL_FETCH_DONE,
});

export const fetchChildScoresReset = () => ({
  type: FETCH_CHILD_SCORES_RESET,
});
