import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Title from "./Title";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";
import PostHbhForm from "./PostHbhForm";
import {
  confirmPostScore,
  confirmPostScoreReset,
  fetchMaxHbhScore,
  fetchMaxHbhScoreReset,
  postHbhScore,
  postHbhScoreReset,
} from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ratingType from "../../../../shared/variables/rating-type";
import { getUserGhinNo } from "../../../../shared/helpers/user-helper";
import ClearAllScoresModal from "../Shared/Modals/ClearAllScoresModal";
import { routesContext } from "../../../../shared/routes/routes-context";
import Loader from "../../../../shared/components/loaders/Loader";
import PostInvalidScoreModal from "../Shared/Modals/PostInvalidScoreModal";
import { golferId } from "../../../../shared/helpers/api-helper";
import PostScoreConfirmationModal from "../Shared/Modals/PostScoreConfirmationModal";
import scoreValidity from "../../../../shared/variables/score-validity";
import jobStatus from "../../../../shared/variables/job-status";
import ClearAllStatsModal from "../Shared/Modals/ClearAllStatsModal";
import { normalizeTeeSetSide } from "../../helpers/post-hbh-score-helper";
import PostScoreApiErrorsModal from "../Shared/Modals/PostScoreApiErrorsModal";
import HbhNotAvailableModal from "../Shared/Modals/HbhNotAvailableModal";
import { getPostScoreApiErrors } from "../../helpers/round-setup-helper";
import navigate from "../../../../shared/navigation/navigate";
import moment from "moment";
import { Helmet } from "react-helmet";
import {
  POST_HBH_TITLE,
  POST_HBH_DESC,
} from "../../../../shared/variables/meta-seo";

class PostHbhScore extends Component {
  formRef;
  static contextType = routesContext;

  constructor(props) {
    super(props);
    this.state = {
      isClearAllScoresModalOpen: false,
      isClearAllStatsModalOpen: false,
      postConfirmationModalOpen: false,
      invalidScoreModalOpen: false,
      isClearAllStatsVisible: false,
      invalidScoreModalMessage: "",
      postScoreApiErrorsModalOpen: false,
      maximumScoresApiErrorsModalOpen: false,
      confirmIncompleteScoreModal: false,
      modalReviewBodyQuestion: "",
      data: {},
    };
  }

  componentDidMount = () => {
    const { fetchMaxHbhScore, history } = this.props;
    const roundSetup = history.location.state.roundSetup;
    fetchMaxHbhScore(
      getUserGhinNo(),
      roundSetup.tees.TeeSetRatingId,
      normalizeTeeSetSide(roundSetup.tees),
      moment(roundSetup.datePlayed).format("YYYY-MM-DD")
    );
  };

  toggleAdvancedStats = () => {
    this.formRef.toggleAdvancedStats();
    this.setState({
      isClearAllStatsVisible: !this.state.isClearAllStatsVisible,
    });
  };

  onSubmit = (values) => {
    const frontHoles = values.holes.slice(0, 9);
    const backHoles = values.holes.slice(9);

    const allFront =
      frontHoles.every((hole) => hole.adjScore !== "") &&
      backHoles.every((hole) => hole.adjScore === "");
    const allBack =
      frontHoles.every((hole) => hole.adjScore === "") &&
      backHoles.every((hole) => hole.adjScore !== "");

    // If the golfer is using scaling,
    // an incomplete score will be posted only after it is confirmed using the modal
    if (
      this.props.useScaling &&
      values.nrOfHoles === "18" &&
      (allFront || allBack)
    ) {
      this.setState({
        confirmIncompleteScoreModal: true,
        modalReviewBodyQuestion: `You have not entered a score for every hole.
        			If you post your score, it will receive an 18-hole
        			Score Differential used for your Handicap Index.`,
        data: values,
      });
    } else {
      this.props.postHbhScore(values);
    }
  };

  alertInvalidScore = (message) => {
    this.setState({
      invalidScoreModalOpen: true,
      invalidScoreModalMessage: message,
    });
  };

  componentDidUpdate(prevProps) {
    const { isClearAllStatsVisible } = this.state;
    const { postedScore, confirmedScore, history } = this.props;
    const courseDetails = history.location.state.courseDetails;

    if (this.isPostHbhScoreSuccess(prevProps)) {
      postedScore.status === scoreValidity.UNDER_REVIEW
        ? this.setState({ postConfirmationModalOpen: true })
        : this.context.postScoreHbhSummary.navigate(
            postedScore,
            courseDetails,
            isClearAllStatsVisible
          );
    } else if (this.isPostHbhScoreFailed(prevProps)) {
      this.setState({ postScoreApiErrorsModalOpen: true });
    } else if (this.maximumHoleScoresFailed(prevProps)) {
      this.setState({ maximumScoresApiErrorsModalOpen: true });
    }
    if (this.isConfirmPostSuccess(prevProps)) {
      this.context.postScoreHbhSummary.navigate(
        confirmedScore,
        courseDetails,
        isClearAllStatsVisible
      );
    }
  }

  isPostHbhScoreSuccess = (prevProps) =>
    this.props.postHbhScoreStatus !== prevProps.postHbhScoreStatus &&
    this.props.postHbhScoreStatus === jobStatus.SUCCESS;

  isPostHbhScoreFailed = (prevProps) =>
    this.props.postHbhScoreStatus === jobStatus.FAILED &&
    this.props.postHbhScoreErrors &&
    this.props.postHbhScoreErrors !== prevProps.postHbhScoreErrors;

  maximumHoleScoresFailed = (prevProps) =>
    this.props.maxHbhStatus === jobStatus.FAILED &&
    this.props.maxHbhError &&
    this.props.maxHbhError !== prevProps.maxHbhError;

  isConfirmPostSuccess = (prevProps) =>
    this.props.confirmPostScoreStatus !== prevProps.confirmPostScoreStatus &&
    this.props.confirmPostScoreStatus === jobStatus.SUCCESS;

  handleClearScoresModal = (open) =>
    this.setState({ isClearAllScoresModalOpen: open });

  handleClearStatsModal = (open) =>
    this.setState({ isClearAllStatsModalOpen: open });

  onCloseHbhModal = () => {
    this.setState({ maximumScoresApiErrorsModalOpen: false });
    navigate.toPath(this.context.postScoreTotalSelectCourse.path);
  };

  render() {
    const {
      isClearAllScoresModalOpen,
      isClearAllStatsModalOpen,
      invalidScoreModalOpen,
      invalidScoreModalMessage,
      postConfirmationModalOpen,
      isClearAllStatsVisible,
      postScoreApiErrorsModalOpen,
      maximumScoresApiErrorsModalOpen,
      confirmIncompleteScoreModal,
      modalReviewBodyQuestion,
      data,
    } = this.state;
    const {
      maxHbhScore,
      history,
      confirmPostScore,
      postedScore,
      postHbhScoreErrors,
      postHbhScoreStatus,
      useScaling,
      hasHandicapIndex,
    } = this.props;
    const locationState = history.location.state;

    return maxHbhScore.length > 0 ? (
      <Fragment>
        <Helmet>
          <title>{POST_HBH_TITLE}</title>
          <meta name="description" content={POST_HBH_DESC} />
        </Helmet>
        <div className="box">
          <Title />
          <DesktopHeader
            onToggleAdvancedStats={this.toggleAdvancedStats}
            onClearAllScores={() => this.handleClearScoresModal(true)}
            onClearAllStats={() => this.handleClearStatsModal(true)}
            isClearAllStatsVisible={isClearAllStatsVisible}
          />
          <div className="box-body tablet-mod">
            <MobileHeader
              onClearAllScores={() => this.handleClearScoresModal(true)}
              onClearAllStats={() => this.handleClearStatsModal(true)}
              isClearAllStatsVisible={isClearAllStatsVisible}
              side={
                locationState.roundSetup.tees.RatingType !== ratingType.BACK
                  ? ratingType.FRONT
                  : ratingType.BACK
              }
            />
            <PostHbhForm
              ref={(ref) => (this.formRef = ref)}
              maxHbhScore={maxHbhScore}
              roundSetup={locationState.roundSetup}
              courseDetails={locationState.courseDetails}
              onSubmit={(values) => this.onSubmit(values)}
              alertInvalidScore={this.alertInvalidScore}
              postHbhScoreStatus={postHbhScoreStatus}
              isScoreReadOnly={false}
              useScaling={useScaling}
              hasHandicapIndex={hasHandicapIndex}
            />
          </div>
        </div>
        <ClearAllScoresModal
          isOpen={isClearAllScoresModalOpen}
          onAbort={() => this.handleClearScoresModal(false)}
          onConfirm={() => {
            this.handleClearScoresModal(false);
            this.formRef.clearAllScores();
          }}
        />
        <ClearAllStatsModal
          isOpen={isClearAllStatsModalOpen}
          onAbort={() => this.handleClearStatsModal(false)}
          onConfirm={() => {
            this.handleClearStatsModal(false);
            this.formRef.clearAllStats();
          }}
        />
        <PostInvalidScoreModal
          message={invalidScoreModalMessage}
          isOpen={invalidScoreModalOpen}
          onClose={() => this.setState({ invalidScoreModalOpen: false })}
        />
        <PostScoreConfirmationModal
          isOpen={postConfirmationModalOpen}
          validationMessage={postedScore?.validation_message_display}
          onAbort={() => this.setState({ postConfirmationModalOpen: false })}
          onConfirm={() => {
            this.setState({ postConfirmationModalOpen: false });
            confirmPostScore(postedScore.id, golferId());
          }}
        />
        <PostScoreConfirmationModal
          isOpen={confirmIncompleteScoreModal}
          validationMessage={modalReviewBodyQuestion}
          onAbort={() => this.setState({ confirmIncompleteScoreModal: false })}
          onConfirm={() => {
            this.setState({
              confirmIncompleteScoreModal: false,
            });
            this.props.postHbhScore(data);
          }}
        />
        <PostScoreApiErrorsModal
          errors={getPostScoreApiErrors(postHbhScoreErrors)}
          isOpen={postScoreApiErrorsModalOpen}
          onClose={() => this.setState({ postScoreApiErrorsModalOpen: false })}
        />
      </Fragment>
    ) : (
      <Fragment>
        <Loader />
        <HbhNotAvailableModal
          course={locationState.courseDetails}
          isOpen={maximumScoresApiErrorsModalOpen}
          onClose={this.onCloseHbhModal}
        />
      </Fragment>
    );
  }

  componentWillUnmount() {
    const { postHbhScoreReset, confirmPostScoreReset, fetchMaxHbhScoreReset } =
      this.props;
    postHbhScoreReset();
    confirmPostScoreReset();
    fetchMaxHbhScoreReset();
  }
}

PostHbhScore.propTypes = {
  history: PropTypes.object.isRequired,
  postedScore: PropTypes.object.isRequired,
  postHbhScoreStatus: PropTypes.string.isRequired,
  confirmPostScore: PropTypes.func.isRequired,
  confirmedScore: PropTypes.object.isRequired,
  confirmPostScoreStatus: PropTypes.string.isRequired,
  maxHbhScore: PropTypes.array.isRequired,
  fetchMaxHbhScore: PropTypes.func.isRequired,
  fetchMaxHbhScoreReset: PropTypes.func.isRequired,
  postHbhScore: PropTypes.func.isRequired,
  postHbhScoreReset: PropTypes.func.isRequired,
  confirmPostScoreReset: PropTypes.func.isRequired,
  postHbhScoreErrors: PropTypes.object,
  maxHbhError: PropTypes.object,
  maxHbhStatus: PropTypes.string.isRequired,
  useScaling: PropTypes.bool.isRequired,
  hasHandicapIndex: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ postScoreReducer, profileReducer }) => {
  const handicapIndex =
    profileReducer.golferProfileFetchReducer.golferProfile?.handicap_index;

  return {
    maxHbhScore: postScoreReducer.maxHbhScoreFetchReducer.maxHbhScore,
    maxHbhStatus:
      postScoreReducer.maxHbhScoreFetchReducer.fetchMaxHbhScoreStatus,
    maxHbhError: postScoreReducer.maxHbhScoreFetchReducer.error,
    postedScore: postScoreReducer.postHbhScoreReducer.postedScore,
    postHbhScoreStatus: postScoreReducer.postHbhScoreReducer.postHbhScoreStatus,
    postHbhScoreErrors: postScoreReducer.postHbhScoreReducer.postHbhScoreErrors,
    confirmedScore:
      postScoreReducer.postScoreConfirmationReducer.confirmedScore,
    confirmPostScoreStatus:
      postScoreReducer.postScoreConfirmationReducer.confirmPostScoreStatus,
    useScaling:
      profileReducer.golferProfileFetchReducer.golferProfile?.use_scaling,
    hasHandicapIndex: handicapIndex != "NH" && handicapIndex != "WD",
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMaxHbhScore,
      fetchMaxHbhScoreReset,
      postHbhScore,
      postHbhScoreReset,
      confirmPostScore,
      confirmPostScoreReset,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PostHbhScore);
