export const FETCH_PRODUCT_ALERT = "FETCH_PRODUCT_ALERT";
export const FETCH_PRODUCT_ALERT_SUCCESS = "FETCH_PRODUCT_ALERT_SUCCESS";
export const HIDE_PRODUCT_ALERT = "HIDE_PRODUCT_ALERT";
export const RESET_PRODUCT_ALERT = "RESET_PRODUCT_ALERT";

export const fetchProductAlert = () => ({
  type: FETCH_PRODUCT_ALERT,
});

export const fetchProductAlertSuccess = (payload) => {
  return {
    type: FETCH_PRODUCT_ALERT_SUCCESS,
    payload,
  };
};

export const hideProductAlert = () => ({
  type: HIDE_PRODUCT_ALERT,
});

export const resetProductAlert = () => ({
  type: RESET_PRODUCT_ALERT,
});
