import { checkApiError } from "../../auth/actions/api-login-methods";
export const FETCH_ASSOCIATIONS = "FETCH_ASSOCIATIONS";
export const FETCH_ASSOCIATIONS_FAILED = "FETCH_ASSOCIATIONS_FAILED";
export const FETCH_ASSOCIATIONS_SUCCESS = "FETCH_ASSOCIATIONS_SUCCESS";

export const fetchAssociations = () => ({
  type: FETCH_ASSOCIATIONS,
});

export function fetchAssociationsFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_ASSOCIATIONS_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchAssociationsSuccess = (payload) => ({
  type: FETCH_ASSOCIATIONS_SUCCESS,
  payload,
});
