import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchMyCourses } from "../actions/index";
import CoursesList from "./CoursesList";
import Loader from "../../../shared/components/loaders/Loader";
import jobStatus from "../../../shared/variables/job-status";
import NoResultsFound from "../../../shared/components/notices/NoResultsFound";
import { NavLink } from "react-router-dom";
import { routesContext } from "../../../shared/routes/routes-context";

class SelectCourse extends Component {
  static contextType = routesContext;
  componentDidMount = () => {
    this.props.fetchMyCourses();
  };

  coursesListEmpty = () => this.props.courses.length > 0;

  render() {
    const { courses, fetchCoursesStatus, navItemLink, searchMode } = this.props;
    return (
      <Fragment>
        <div className="box">
          <div className="box-head my-courses-head">
            <h1 className="box-title">My Courses</h1>
          </div>
          <div className="box-body">
            <div className="item-list courses">
              {fetchCoursesStatus === jobStatus.PROCESSING &&
              !this.coursesListEmpty() ? (
                <Loader />
              ) : this.coursesListEmpty() ? (
                <CoursesList
                  navLinkPath={navItemLink}
                  courses={courses}
                  searchMode={searchMode}
                />
              ) : (
                <NoResultsFound>
                  <p style={{ margin: "30px 0" }}>
                    There are no courses selected. Please add a course to your
                    list.
                  </p>
                </NoResultsFound>
              )}
            </div>
          </div>
          {courses.length < 20 ? (
            <div className="add-courses-button">
              <NavLink
                className="btn fill cardinal auto"
                to={this.context.addCourses.path}
              >
                Add Courses
              </NavLink>
            </div>
          ) : (
            <div className="add-courses-button">
              <div
                className="btn fill cardinal auto"
                style={{ pointerEvents: "none" }}
              >
                Maximum courses number reached
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ selectMyCoursesReducer }) => {
  return {
    courses: selectMyCoursesReducer.myCoursesFetchReducer.courses,
    fetchCoursesStatus:
      selectMyCoursesReducer.myCoursesFetchReducer.fetchCoursesStatus,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchMyCourses }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectCourse);
