import { checkApiError } from "../../auth/actions/api-login-methods";

export const GET_AVERAGE_SCORE_PER_HOLE = "GET_AVERAGE_SCORE_PER_HOLE";
export const GET_AVERAGE_SCORE_PER_HOLE_FAILED =
  "GET_AVERAGE_SCORE_PER_HOLE_FAILED";
export const GET_AVERAGE_SCORE_PER_HOLE_SUCCESS =
  "GET_AVERAGE_SCORE_PER_HOLE_SUCCESS";

export const getAverageScorePerHole = (golfer_id, tee_set_id) => ({
  type: GET_AVERAGE_SCORE_PER_HOLE,
  payload: { golfer_id, tee_set_id },
});

export function getAverageScorePerHoleFailed(error) {
  return (dispatch) => {
    dispatch({ type: GET_AVERAGE_SCORE_PER_HOLE_FAILED });
    dispatch(checkApiError(error));
  };
}

export const getAverageScorePerHoleSuccess = (payload) => {
  return {
    type: GET_AVERAGE_SCORE_PER_HOLE_SUCCESS,
    payload,
  };
};
