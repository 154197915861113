/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink } from "react-router-dom";
import { routesContext } from "../../shared/routes/routes-context";
import { getDisplayScore } from "../../shared/helpers/ui-helper";
import { userExistInFollowed } from "../../shared/helpers/follow-helper";
import { followGolfer, unFollowGolfer } from "../golfers/actions";
import { getGolferId } from "../../shared/helpers/user-helper";
import YesNoModal from "../../shared/components/modals/YesNoModal";
import { encrypt } from "../../shared/helpers/encoder";

class GolfersListItem extends Component {
  static contextType = routesContext;

  state = {
    showConfirmModal: false,
  };

  onCloseConfirmModal = () => this.setState({ showConfirmModal: false });

  onClickUnFollow = () => this.setState({ showConfirmModal: true });

  onConfirmUnFollow = () => {
    this.onCloseConfirmModal();
    const { golfer, unFollowGolfer } = this.props;
    const encryptedGolferId = encrypt(getGolferId(golfer));
    unFollowGolfer(encryptedGolferId, golfer.club_id);
  };

  onClickFollow = () => {
    const { golfer, followGolfer } = this.props;
    const encryptedGolferId = encrypt(getGolferId(golfer));
    followGolfer(encryptedGolferId, golfer.club_id);
  };

  renderFollowButton() {
    const { golfer } = this.props;
    const golferId = getGolferId(golfer);

    const isFollowed = userExistInFollowed(golferId, golfer.club_id);
    return (
      <Fragment>
        {isFollowed ? (
          <span onClick={this.onClickUnFollow} className="star active" />
        ) : (
          <span className="star" onClick={this.onClickFollow} />
        )}
      </Fragment>
    );
  }

  render() {
    const { golfer } = this.props;
    const { showConfirmModal } = this.state;
    const encryptedGolferId = encrypt(getGolferId(golfer));
    const golferRoute = this.context.golfer.path
      .replace(":id", encryptedGolferId)
      .replace(":club_id", golfer.club_id);
    const golferName = `${golfer.first_name} ${golfer.last_name} ${
      golfer.suffix || ""
    }`;
    const handicap = golfer.handicap_index_display || golfer.handicap_index;
    return (
      <Fragment>
        <li key={encryptedGolferId}>
          <span className="item">
            {this.renderFollowButton()}
            <NavLink className="item details" to={golferRoute}>
              <span className="name">{golferName}</span>
              <span className="club">{golfer.club_name}</span>
            </NavLink>
            <NavLink className="item index" to={golferRoute}>
              {getDisplayScore(handicap)}
            </NavLink>
          </span>
        </li>
        <YesNoModal
          isOpen={showConfirmModal}
          onAbort={this.onCloseConfirmModal}
          onConfirm={this.onConfirmUnFollow}
        >
          <p>{`Are you sure you want to unfollow ${golferName}?`}</p>
        </YesNoModal>
      </Fragment>
    );
  }
}

GolfersListItem.propTypes = {
  golfer: PropTypes.shape({}).isRequired,
  followGolfer: PropTypes.func.isRequired,
  unFollowGolfer: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ followGolfer, unFollowGolfer }, dispatch);

const mapStateToProps = ({ golfersReducer }) => ({
  golfers: golfersReducer.followedGolfersReducer.golfers,
});

export default connect(mapStateToProps, mapDispatchToProps)(GolfersListItem);
