import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./shared/store/index";
import navigationHistory from "./shared/navigation/navigation-history";
import { Router } from "react-router-dom";
import Tracking from "./shared/tracking/index";

import { routesContext } from "./shared/routes/routes-context";
import { routes } from "./shared/routes/index";

Tracking.initialize();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <routesContext.Provider value={routes}>
        <Router history={navigationHistory}>
          <App />
        </Router>
      </routesContext.Provider>
    </PersistGate>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
