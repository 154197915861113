import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";

function OverviewRedirect() {
  const deepLinkUrl = "com.ghin://overview";
  const storeUrl = "https://onelink.to/unxcvv";

  const redirectURL = isMobile ? deepLinkUrl : storeUrl;
  const text = isMobile ? "View Your GHIN Rewind" : "Go To USGA Website";

  const downloadFromStore = (
    <div
      style={{
        marginTop: "25px",
        textAlign: "center",
      }}
    >
      or download the GHIN app from{" "}
      <a href={"https://onelink.to/unxcvv"}>here</a>
    </div>
  );

  useEffect(() => {
    window.location = redirectURL;
  }, []);

  const checkAndRedirectToStore = () => {
    let timeout;
    timeout = setTimeout(function () {
      // window.location = storeUrl;

      alert("The GHIN app is not installed on your phone.");
    }, 1200);

    window.location = redirectURL;

    window.onblur = function () {
      clearTimeout(timeout);
    };
  };

  return (
    <div
      style={{
        margin: isMobile ? "30% 0%" : "20% 0%",
        textAlign: "center",
      }}
    >
      <a
        className="btn fill cardinal background-focus"
        href={redirectURL}
        style={{
          width: "100%",
          minWidth: "180px",
        }}
        onClick={checkAndRedirectToStore}
      >
        {text}
      </a>
      {isMobile && downloadFromStore}
    </div>
  );
}

export default OverviewRedirect;
