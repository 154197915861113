import { checkApiError } from "../../auth/actions/api-login-methods";

export * from "./calculator-course-fetch-actions";

export const FETCH_CALCULATOR_COURSE_FAILED = "FETCH_CALCULATOR_COURSE_FAILED";

export function fetchCalculatorCourseFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_CALCULATOR_COURSE_FAILED });
    dispatch(checkApiError(error));
  };
}
