import {
  FETCH_CALCULATOR_GOLFER_TEE,
  fetchCalculatorGolferTeeSuccess,
  fetchCalculatorGolferTeeFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { catchError, map, flatMap } from "rxjs/operators";
import { from, of } from "rxjs";
import SupportingCalculationApi from "../../../services/supporting-calculation-api";
import { getGolferId } from "../../../shared/helpers/user-helper-b";
import moment from "moment";

export default (action$) =>
  action$
    .pipe(ofType(FETCH_CALCULATOR_GOLFER_TEE))
    .pipe(flatMap(({ payload }) => fetchCalculatorGolferTee(payload)));

const fetchCalculatorGolferTee = (payload) =>
  from(
    SupportingCalculationApi.getCourseHandicaps(
      getGolferId(payload.golfer),
      payload.courseId,
      moment(new Date()).format("YYYY-MM-DD"),
      payload.manualGolferId,
      payload.handicapIndex
    )
  )
    .pipe(
      map((response) =>
        fetchCalculatorGolferTeeSuccess(response.data, payload.golfer)
      )
    )
    .pipe(
      catchError((error) =>
        of(fetchCalculatorGolferTeeFailed(payload.golfer, error))
      )
    );
