import { checkApiError } from "../../auth/actions/api-login-methods";

export const FETCH_AVERAGE_SCORED_SCORES = "FETCH_AVERAGE_SCORED_SCORES";
export const FETCH_AVERAGE_SCORED_SCORES_FAILED =
  "FETCH_AVERAGE_SCORED_SCORES_FAILED";
export const FETCH_AVERAGE_SCORED_SCORES_SUCCESS =
  "FETCH_AVERAGE_SCORED_SCORES_SUCCESS";

export const fetchAverageScores = (filter, course_id, tee_set_id) => ({
  type: FETCH_AVERAGE_SCORED_SCORES,
  payload: { filter, course_id, tee_set_id },
});

export function fetchAverageScoresFailed(error) {
  return (dispatch) => {
    dispatch({ type: FETCH_AVERAGE_SCORED_SCORES_FAILED });
    dispatch(checkApiError(error));
  };
}

export const fetchAverageScoresSuccess = (data) => {
  return {
    type: FETCH_AVERAGE_SCORED_SCORES_SUCCESS,
    payload: { data },
  };
};
