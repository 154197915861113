import { checkApiError } from "../../auth/actions/api-login-methods";

export const POST_TOTAL_SCORE = "POST_TOTAL_SCORE";
export const POST_TOTAL_SCORE_FAILED = "POST_TOTAL_SCORE_FAILED";
export const POST_TOTAL_SCORE_SUCCESS = "POST_TOTAL_SCORE_SUCCESS";
export const POST_TOTAL_SCORE_RESET = "POST_TOTAL_SCORE_RESET";

export const postTotalScore = (payload) => ({
  type: POST_TOTAL_SCORE,
  payload: { ...payload },
});

export function postTotalScoreFailed(error) {
  return (dispatch) => {
    dispatch({ type: POST_TOTAL_SCORE_FAILED, payload: error });
    dispatch(checkApiError(error));
  };
}

export const postTotalScoreSuccess = (payload) => ({
  type: POST_TOTAL_SCORE_SUCCESS,
  payload,
});

export const postTotalScoreReset = (payload) => ({
  type: POST_TOTAL_SCORE_RESET,
  payload,
});
